import { config } from '../config';
const currentVersion = '1.0';

export function getAvailableProducts() {
  return {
    method: 'GET',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/products`,
  };
}

// default to paymentMethod: 1, (external wallet). paymentMethod: 2 is Stripe, paymentMethod: 15 is switchpay
export function createInvoice({
  products,
  paymentMethod = 1,
  memberDiscountCouponRewardIds = [],
}) {
  return {
    method: 'POST',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/invoices`,
    data: {
      products,
      paymentMethod,
      memberDiscountCouponRewardIds,
    },
  };
}

export function submitInvoiceForPayment({ invoiceId, passcode, symbol }) {
  return {
    method: 'POST',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/invoices/${invoiceId}/payments`,
    data: {
      walletPassword: passcode,
      coinSymbol: symbol,
    },
  };
}

export function cancelInvoice({ invoiceId }) {
  return {
    method: 'PUT',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/invoices/${invoiceId}/cancel`,
  };
}

export function queryInvoiceStatus({ invoiceId }) {
  return {
    method: 'GET',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/invoices/${invoiceId}`,
  };
}

export function getProductByID({ productId }) {
  return {
    method: 'GET',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/products/${productId}`,
  };
}

export function getCart() {
  return {
    method: 'get',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/cart`,
  };
}

export function addToCart({ productId, quantity }) {
  return {
    method: 'post',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/cart`,
    data: {
      productId,
      quantity,
    },
  };
}

export function removeFromCart({ productId, quantity }) {
  return {
    method: 'delete',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/cart/${productId}/${quantity}`,
  };
}

export function clearUserCart() {
  return {
    method: 'delete',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/cart`,
  };
}

export function getSwitchPaySession(walletId) {
  return {
    method: 'get',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/switchpay/get-session/${walletId}`,
  };
}

export function getUserCoupons() {
  return {
    method: 'get',
    url: `${config.core.shoppingServiceUrl}/v${currentVersion}/coupons`,
  };
}
