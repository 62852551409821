import React from 'react';
import ScrollUpButton from 'react-scroll-up';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Link,
  Paper,
  Select,
  styled,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { AlertBody, LoadingBox } from '@/common/index';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronUpIcon from '@mui/icons-material/ExpandLess';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Hooks
import { useIsMobile } from '@/hooks/useIsMobile';

// Styles
import { colors } from '@/theme';

const CardItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderBottom: `1px solid ${colors.grey[300]}`,
  padding: 30,
});

const CardItemTitle = ({ name }) => (
  <Typography
    sx={{ fontSize: '16px', minWidth: '110px', marginRight: '10px', fontWeight: '700' }}
  >
    {name}
  </Typography>
);

const SelectComponent = ({ value, onChange, pageSizeMenuItems, isMobile }) => (
  <Select
    labelId="rows-per-page-select-label"
    id="rows-per-page-select"
    value={value}
    onChange={onChange}
    variant="outlined"
    size="small"
    style={{
      marginLeft: isMobile ? '7px' : '20px',
      marginRight: isMobile ? '15px' : '25px',
    }}
    MenuProps={{
      PaperProps: {
        style: {
          padding: '0',
        },
      },
    }}
  >
    {pageSizeMenuItems.map((item) => (
      <MenuItem
        key={item}
        value={item}
        style={{ justifyContent: 'center', padding: '20px 35px' }}
      >
        {item}
      </MenuItem>
    ))}
  </Select>
);

const TransactionHistoryTable = ({
  isLoading,
  tableData,
  tableSpecs,
  handlePagination,
  handleChangeRowsPerPage,
  createSortHandler,
  isActiveSortColumn,
  pageSizeMenuItems,
  sortableColumns,
}) => {
  const isMobile = useIsMobile(600);

  const isSortableColumn = (column) => sortableColumns.includes(column);

  const calculatePageCount = () => {
    if (tableSpecs.totalElements <= tableSpecs.size) {
      return `1 - ${tableSpecs.totalElements} of ${tableSpecs.totalElements}`;
    }

    const start = tableSpecs.page === 0 ? 1 : tableSpecs.size * tableSpecs.page + 1;
    const end =
      tableSpecs.page === 0
        ? tableSpecs.size
        : Math.min(tableSpecs.size * (tableSpecs.page + 1), tableSpecs.totalElements);
    return `${start} - ${end} of ${tableSpecs.totalElements}`;
  };

  const renderTable = () => (
    <TableContainer
      component={Paper}
      sx={{
        padding: '0px',
        border: `1px solid ${colors.grey[300]}`,
        borderRadius: '8px',
        boxShadow: 'none',
      }}
    >
      <Table size="medium">
        <TableHead>
          <TableRow>
            {tableData.columns.map((column) => (
              <TableCell
                key={column.accessor}
                sortDirection={
                  isSortableColumn(column.accessor)
                    ? tableSpecs.direction.toLowerCase()
                    : false
                }
                sx={{ fontWeight: '700' }}
              >
                {isSortableColumn(column.accessor) ? (
                  <TableSortLabel
                    active={isActiveSortColumn(column.accessor)}
                    direction={
                      isActiveSortColumn(column.accessor)
                        ? tableSpecs.direction.toLowerCase()
                        : 'desc'
                    }
                    onClick={() => createSortHandler(column.accessor)}
                  >
                    {column.label}
                  </TableSortLabel>
                ) : (
                  column.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => window.open(row.url, '_blank', 'noopener,noreferrer')}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colors.primary[50],
                },
              }}
            >
              {tableData.columns.map((column) => (
                <TableCell key={`cell-${row.id}-${column.label}`}>
                  {row[column.accessor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ padding: '16px 0' }}
      >
        <span>Rows per page:</span>
        <SelectComponent
          value={tableSpecs.size}
          onChange={handleChangeRowsPerPage}
          pageSizeMenuItems={pageSizeMenuItems}
          isMobile={isMobile}
        />
        {calculatePageCount()}
        <IconButton
          aria-label="first page"
          onClick={() => handlePagination('first')}
          disabled={tableSpecs.page === 0}
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          aria-label="previous page"
          onClick={() => handlePagination('prev')}
          disabled={tableSpecs.page === 0}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          aria-label="next page"
          onClick={() => handlePagination('next')}
          disabled={tableSpecs.page >= tableSpecs.totalPages - 1}
        >
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          aria-label="last page"
          onClick={() => handlePagination('last')}
          disabled={tableSpecs.page >= tableSpecs.totalPages - 1}
        >
          <LastPageIcon />
        </IconButton>
      </Box>
    </TableContainer>
  );

  const TransactionTableCard = ({ transaction }) => {
    return (
      <Card
        sx={{
          minWidth: 275,
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '5px',
          boxShadow: 'none',
          padding: 0,
        }}
      >
        <CardContent sx={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}>
          {tableData.columns.map((column) => (
            <CardItem key={column.accessor}>
              {column.accessor !== 'link' && <CardItemTitle name={column.label} />}
              {column.accessor === 'link' ? (
                <Link
                  href={transaction.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  sx={{
                    width: '100%',
                    border: '1px solid',
                    backgroundColor: colors.primary.main,
                    color: colors.common.white,
                  }}
                  component={Button}
                >
                  <OpenInNewIcon
                    color="white"
                    sx={{ width: '16px', height: '16px', marginRight: '10px' }}
                  />
                  Etherscan
                </Link>
              ) : (
                <Typography component="div" sx={{ fontSize: '15px' }}>
                  {transaction[column.accessor]}
                </Typography>
              )}
            </CardItem>
          ))}
        </CardContent>
      </Card>
    );
  };

  const renderCards = () => (
    <Box mt={3}>
      {tableData?.rows.map((transaction, i) => (
        <Box key={transaction.url}>
          <TransactionTableCard transaction={transaction} />
          {i !== tableData.length - 1 && <Box sx={{ height: '20px' }} />}
        </Box>
      ))}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        sx={{ fontSize: '12px' }}
      >
        Rows per page:
        <SelectComponent
          value={tableSpecs.size}
          onChange={handleChangeRowsPerPage}
          pageSizeMenuItems={pageSizeMenuItems}
          isMobile={isMobile}
        />
        {calculatePageCount()}
        <IconButton
          aria-label="previous page"
          onClick={() => handlePagination('prev')}
          disabled={tableSpecs.page === 0}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          aria-label="next page"
          onClick={() => handlePagination('next')}
          disabled={tableSpecs.page >= tableSpecs.totalPages - 1}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <ScrollUpButton
        ContainerClassName="scroll-up-button-container"
        TransitionClassName="scroll-up-button-transition"
        showUnder={2000}
        style={{
          position: 'fixed',
          bottom: 10,
          right: 0,
          left: 0,
          margin: 'auto',
          cursor: 'pointer',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'linear',
          transitionDelay: '0s',
          borderRadius: '40px',
          backgroundColor: colors.primary.main,
          color: colors.surfaces.layerTwo,
          width: 140,
          height: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          Back to Top
          <ChevronUpIcon style={{ marginLeft: '5px', transform: 'translateY(2px)' }} />
        </div>
      </ScrollUpButton>
    </Box>
  );

  return (
    <section>
      <header>
        <Typography variant="h3" mb={2}>
          Transaction History
        </Typography>
      </header>
      <Box data-core-qa="tableScroll">
        {isLoading ? (
          <LoadingBox />
        ) : tableData?.rows && tableData?.rows.length ? (
          isMobile ? (
            renderCards()
          ) : (
            renderTable()
          )
        ) : (
          <Box mt={2} mx={2}>
            <Alert severity={'info'} variant="outlined" sx={{ border: 'none' }}>
              <AlertBody
                header={'No Transactions Available'}
                body={"There aren't any transactions available at this time."}
              />
            </Alert>
          </Box>
        )}
      </Box>
    </section>
  );
};

export default TransactionHistoryTable;
