import {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import { lowerCase } from 'lodash';
// CMS
import { URLs } from '@/webiny/queries';
import { fetchWithFallback } from '@/webiny/api';
const { getToken } = URLs;
// Hooks
import { useAuth } from '@/hooks/useAuth';
import { useFeatureFlags } from './FeatureFlagProvider';
import { useWeb3 } from './Web3Provider';
// Utils
import { getCoinConversion } from '@/resources/wallet-service.resource';
import { toCrypto } from '@/utils/coin';

const defaultCurrency = {
  name: 'Ethereum',
  symbol: 'ETH',
  allowPurchases: true,
  decimalPrecision: 18,
};

export const CurrencyContext = createContext();

export function useCurrency() {
  return useContext(CurrencyContext);
}

export function CurrencyProvider({ children }) {
  const { featureFlags } = useFeatureFlags();
  const { fetcher } = useAuth();
  const { erc20Configurations } = useWeb3();
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [currency, setCurrency] = useState('ETH');
  const [currencyConversion, setCurrencyConversion] = useState(1);
  const [conversionError, setConversionError] = useState(null);
  const [useCreditCard, setUseCreditCard] = useState(false);
  const [isSwitchPay, setIsSwitchPay] = useState(false);
  // transferPrecision is a map of currency symbols to their decimal precision for all coins, not just accepted erc20
  const [transferPrecision, setTransferPrecision] = useState({});

  const additionalCurrencies = useMemo(
    () => [
      {
        name: 'Credit / Debit Card',
        symbol: 'Credit / Debit Card',
        allowPurchases: featureFlags?.ShowStripe || featureFlags?.EnableInvoiceSwitchPay,
        decimalPrecision: 2,
      },
    ],
    [featureFlags.ShowStripe, featureFlags.EnableInvoiceSwitchPay]
  );

  const getCurrencyConversion = useCallback(
    ({ currency }) => {
      if (!useCreditCard) {
        return fetcher(getCoinConversion({ symbol: currency, currency: 'USD' }))
          .then((rate) => {
            setConversionError(null);
            setCurrencyConversion(rate.price || -1);
            setCurrency(currency);
            return rate.price;
          })
          .catch((err) => {
            console.error(err);
            setConversionError({
              header: 'Currency conversion error',
              message: `${currency} conversion is unavailable at this time.`,
            });
            setCurrencyConversion(-1);
            setCurrency(currency);
            return -1;
          });
      }
    },
    [fetcher, useCreditCard]
  );

  // fetch accepted currencies on mount
  useEffect(() => {
    async function fetchCurrencies() {
      try {
        const erc20 = erc20Configurations.filter((coin) => coin.allowPurchases);
        const currencies = [defaultCurrency, ...additionalCurrencies, ...erc20];

        const currencyImageUrls = await Promise.allSettled(
          currencies.map((currency) => {
            const slug = lowerCase(currency.symbol);
            return fetchWithFallback(getToken(slug), {});
          })
        );

        const urls = currencyImageUrls.reduce((acc, currency, index) => {
          currency.status === 'fulfilled' && currency.value.icon
            ? (acc[currencies[index].symbol] = currency.value.icon)
            : (acc[currencies[index].symbol] = ''); // if we don't find an icon, we'll just use an empty string to have the browser show a broken image icon
          return acc;
        }, {});

        const all = currencies.map((currency) => ({
          ...currency,
          icon: urls[currency.symbol],
        }));
        setAllCurrencies(all);

        const precisionMap = all.reduce((acc, curr) => {
          acc[curr.symbol] = curr.decimalPrecision;
          return acc;
        }, {});
        setTransferPrecision(precisionMap);
      } catch (err) {
        console.error(err);
        const all = [defaultCurrency, ...additionalCurrencies];
        setAllCurrencies(all);
        const precisionMap = all.reduce((acc, curr) => {
          acc[curr.symbol] = curr.decimalPrecision;
          return acc;
        }, {});
        setTransferPrecision(precisionMap);
      }
    }
    erc20Configurations.length && fetchCurrencies();
  }, [
    additionalCurrencies,
    featureFlags?.ShowStripe,
    featureFlags?.EnableInvoiceSwitchPay,
    erc20Configurations,
  ]);

  // fetch conversion rate on mount and when currency changes
  useEffect(() => {
    getCurrencyConversion({ currency });
  }, [currency]);

  const handleSetCurrency = (value) => {
    setUseCreditCard(value === 'Credit / Debit Card');
    setCurrency(value);
  };

  const handleConvertUsdToCoin = (amount) => {
    if (!amount) return 0;
    const precision =
      currency === 'ETH' || currency === 'BTC'
        ? 8
        : amount?.toString().split('.')[1]?.length;
    return toCrypto({
      amount,
      precision,
      conversion: currencyConversion,
    });
  };

  return (
    <CurrencyContext.Provider
      value={{
        allCurrencies,
        conversionError,
        currency,
        currencyConversion,
        handleConvertUsdToCoin,
        handleSetCurrency,
        isSwitchPay,
        setCurrency,
        setIsSwitchPay,
        transferPrecision,
        useCreditCard,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
}
