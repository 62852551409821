import { ethers } from 'ethers';
// Utils
import { brand } from '@/brand/brand';
import { getChainInfo, getTokenConversions } from '@/resources/wallet-service.resource';
// Icons
import BTC from '@/icons/logos/bitcoin.svg';
import ETH from '@/icons/logos/ethereum.svg';
import USDC from '@/icons/logos/usdc_icon.png';
import USDT from '@/icons/logos/usdt-token.svg';
import GALA from '@/icons/logos/gala-lite-node.svg';
import IZE from '@/icons/logos/galvan-dark.svg';
import BUSD from '@/icons/logos/busd.jpg';

// See assets/icons/logos for brand icons available to Core - update as necessary, key should match coin symbol
const COIN_ICONS = {
  BTC: BTC.src,
  BUSD: BUSD.src,
  ETH: ETH.src,
  GALA: GALA.src,
  IZE: IZE.src,
  USDC: USDC.src,
  USDT: USDT.src,
  WBTC: BTC.src,
  WETH: ETH.src,
  [brand.coin]: brand.tokenIcon,
  [`${brand.coin}-P`]: brand.tokenIconL2,
};

const MULTICALL_ABI = [
  'function aggregate(tuple(address target, bytes callData)[] calls) public view returns (uint256 blockNumber, bytes[] returnData)',
];

export const extractCoinData = (coin = null) => {
  if (!coin) return;
  return {
    useEthGas: coin.coinData.useEthGas,
    priceUsd: coin.coinData.priceUsd,
    coinSymbol: coin.coinData.symbol,
    coinAmt: coin.coinData.balance,
    coinLogo: coin.coinData.icon,
    coinName: coin.coinData.name,
    hasError: !!coin.coinData.hasError,
    usd: coin.coinData.usd || 0,
    walletId: coin.coinData.address,
    valid: coin.coinData.canSendFunds,
    decimalPrecision: coin.coinData.decimalPrecision,
  };
};

export async function handleGetChainInfo(fetcher, retryCount = 0) {
  try {
    return await fetcher(getChainInfo());
  } catch (error) {
    if (retryCount < 3) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return handleGetChainInfo(fetcher, retryCount + 1);
    } else {
      return null;
    }
  }
}

const getBrandCoinData = async (ethCoin, fetcher, l2ChainInfo, setError) => {
  if (
    !ethCoin?.address ||
    !brand.pages.dashboard.useBrandCoin ||
    !l2ChainInfo?.blockExplorerUrl
  ) {
    return null;
  }
  const brandL2Coin = {
    ...ethCoin,
    useEthGas: false,
    balance: '0',
    balanceUsd: '0',
    name: `${brand.brandChainName} Rewards`,
    symbol: `${brand.coin}-P`,
    icon: COIN_ICONS[`${brand.coin}-P`],
    decimalPrecision: l2ChainInfo.decimalPrecision,
  };

  try {
    const l2Provider = new ethers.providers.JsonRpcProvider(l2ChainInfo?.rpcNodeUrl);

    const balance = await l2Provider.getBalance(ethCoin.address);
    const formattedBalance = ethers.utils.formatUnits(
      balance,
      l2ChainInfo.decimalPrecision
    );

    return {
      coinData: {
        ...brandL2Coin,
        balance: formattedBalance,
        priceUsd: null,
        hasError: false,
        usd: 0,
      },
    };
  } catch (error) {
    console.error({ error });
    setError({ coin: true });
    return {
      coinData: {
        ...brandL2Coin,
        hasError: true,
      },
    };
  }
};

export async function handleGetWallet({
  addresses,
  dispatch,
  duplicateWallets,
  erc20Configurations,
  fetcher,
  isLegacy,
  l2ChainInfo,
  provider,
  setError,
  setLoading,
}) {
  setError('reset');

  if (setLoading) {
    dispatch({
      type: isLegacy ? 'SET_WALLET_LOADING' : 'SET_CORE_WALLET_LOADING',
      payload: true,
    });
  }

  const network = await provider?.getNetwork();
  const userETHAddress = addresses?.eth;
  const userBtcAddress = addresses?.btc;
  const filteredTokens = erc20Configurations.filter((token) => token.allowInApp);
  const symbols = ['ETH', 'BTC', ...filteredTokens.map((token) => token.symbol)];
  const balanceOfFragment = 'function balanceOf(address) view returns (uint256)';
  const tokenPrices = await fetcher(getTokenConversions({ symbols, currency: 'USD' }));
  const coins = [];
  const isTestnet = network.chainId !== 1;
  try {
    // Get ETH address and initial coin data
    if (userBtcAddress) {
      try {
        const btcBalance = await getBTCBalance(
          userBtcAddress,
          isTestnet,
          fetcher,
          isLegacy,
          dispatch
        );
        coins.push({
          coinData: {
            address: userBtcAddress,
            balance: btcBalance,
            balanceUsd: (btcBalance * (tokenPrices['btc'] || 0)).toString(),
            canSendFunds: true,
            hasError: btcBalance === null,
            icon: COIN_ICONS['BTC'],
            name: 'Bitcoin',
            symbol: 'BTC',
            useEthGas: false,
            priceUsd: tokenPrices['btc'] || tokenPrices['btc'] || null,
            usd: (btcBalance * (tokenPrices['btc'] || 0)).toString(),
          },
        });
      } catch {
        coins.push({
          coinData: {
            address: userBtcAddress,
            balance: null,
            balanceUsd: null,
            canSendFunds: false,
            hasError: true,
            icon: COIN_ICONS['BTC'],
            name: 'Bitcoin',
            symbol: 'BTC',
            useEthGas: false,
            priceUsd: tokenPrices['btc'] || tokenPrices['btc'] || null,
            usd: 0,
          },
        });
      }
    } else {
      coins.push({
        coinData: {
          address: '',
          balance: null,
          balanceUsd: null,
          canSendFunds: false,
          hasError: true,
          icon: COIN_ICONS['BTC'],
          name: 'Bitcoin',
          symbol: 'BTC',
          useEthGas: false,
          priceUsd: tokenPrices['btc'] || tokenPrices['btc'] || null,
          usd: 0,
          decimalPrecision: 8,
        },
      });
    }

    if (provider) {
      try {
        const multicallContract = new ethers.Contract(
          '0xcA11bde05977b3631167028862bE2a173976CA11',
          MULTICALL_ABI,
          provider
        );

        const iface = new ethers.utils.Interface([balanceOfFragment]);
        const calls = filteredTokens.map((token) => ({
          target: token.contractAddress,
          callData: iface.encodeFunctionData('balanceOf', [userETHAddress]),
        }));

        // Get ETH balance
        try {
          const ethBalance = await provider.getBalance(userETHAddress);
          coins.push({
            coinData: {
              address: userETHAddress,
              balance: ethers.utils.formatUnits(ethBalance, 18),
              balanceUsd: (
                ethers.utils.formatUnits(ethBalance, 18) * (tokenPrices['ETH'] || 0)
              ).toString(),
              priceUsd: tokenPrices['eth'] || tokenPrices['eth'] || null,
              canSendFunds: true,
              hasError: false,
              icon: COIN_ICONS['ETH'],
              name: 'Ethereum',
              symbol: 'ETH',
              useEthGas: true,
              usd: (
                ethers.utils.formatUnits(ethBalance, 18) * (tokenPrices['eth'] || 0)
              ).toString(),
            },
            decimalPrecision: 18,
          });
        } catch (ethError) {
          coins.push({
            coinData: {
              address: userETHAddress,
              balance: null,
              balanceUsd: null,
              canSendFunds: false,
              hasError: true,
              icon: COIN_ICONS['ETH'],
              name: 'Ethereum',
              symbol: 'ETH',
              useEthGas: true,
              priceUsd: tokenPrices['eth'] || tokenPrices['eth'] || null,
              usd: 0,
              decimalPrecision: 18,
            },
          });
        }

        // Get token balances
        try {
          const [, returnData] = await multicallContract.aggregate(calls);

          filteredTokens.forEach((token, index) => {
            try {
              const balance = ethers.utils.formatUnits(
                iface.decodeFunctionResult('balanceOf', returnData[index])[0],
                token.decimalPrecision
              );

              const price =
                tokenPrices[token.symbol.toLowerCase()] ||
                tokenPrices[token.symbol] ||
                null;
              const balanceUsd = (Number(balance) * price).toString();

              coins.push({
                coinData: {
                  address: userETHAddress,
                  balance,
                  balanceUsd,
                  canSendFunds: true,
                  hasError: false,
                  icon: COIN_ICONS[token.symbol],
                  name: token.name || token.symbol,
                  symbol: token.symbol,
                  useEthGas: true,
                  priceUsd: price,
                  usd: balanceUsd,
                  decimalPrecision: token.decimalPrecision,
                },
              });
            } catch (tokenError) {
              coins.push({
                coinData: {
                  address: userETHAddress,
                  balance: null,
                  balanceUsd: null,
                  canSendFunds: false,
                  hasError: true,
                  icon: COIN_ICONS[token.symbol],
                  name: token.name || token.symbol,
                  symbol: token.symbol,
                  useEthGas: true,
                  usd: 0,
                  decimalPrecision: token.decimalPrecision,
                },
              });
            }
          });
        } catch (tokensError) {
          filteredTokens.forEach((token) => {
            coins.push({
              coinData: {
                address: userETHAddress,
                balance: null,
                balanceUsd: null,
                canSendFunds: false,
                hasError: true,
                icon: COIN_ICONS[token.symbol],
                name: token.name || token.symbol,
                symbol: token.symbol,
                useEthGas: true,
                usd: 0,
                decimalPrecision: token.decimalPrecision,
              },
            });
          });
        }
      } catch (providerError) {
        setError({ provider: true });
      }
    }

    // Handle brand coin
    const ethCoin = coins.find((coin) => coin?.coinData?.symbol === 'ETH')?.coinData;
    const brandCoin = await getBrandCoinData(ethCoin, fetcher, l2ChainInfo, setError);

    // Combine and process results
    const coinsArr = brandCoin ? [brandCoin, ...coins] : coins;
    const walletItemsArr = coinsArr.map(extractCoinData).filter(Boolean);

    if (walletItemsArr.length) {
      dispatch({
        type: isLegacy ? 'SET_WALLET_STATE' : 'SET_CORE_WALLET_STATE',
        payload: walletItemsArr,
      });
    }
    // If duplicate wallets, retain "legacy" state information
    if (duplicateWallets) {
      dispatch({
        type: 'SET_HAS_DUPLICATE_WALLETS',
        payload: true,
      });
      dispatch({
        type: 'SET_WALLET_STATE',
        payload: walletItemsArr,
      });
    }

    return walletItemsArr;
  } finally {
    // Always reset loading states
    dispatch({
      type: isLegacy ? 'SET_WALLET_LOADING' : 'SET_CORE_WALLET_LOADING',
      payload: false,
    });
  }
}

export async function getBTCBalance(
  address,
  isTestnet = false,
  fetcher,
  isLegacy,
  dispatch
) {
  try {
    // Use BlockCypher API (free, reliable)
    const network = isTestnet ? 'test3' : 'main';

    const params = new URLSearchParams({
      address: address,
      network: network,
    });

    const response = await fetcher({
      url: `/api/coins/btcBalance?${params}`,
    });
    if (!response?.balance) {
      // Set error state when balance is missing
      dispatch({
        type: isLegacy ? 'SET_HAS_WALLET_ERROR' : 'SET_HAS_CORE_WALLET_ERROR',
        payload: true,
      });
      dispatch({
        type: isLegacy ? 'SET_WALLET_LOADING' : 'SET_CORE_WALLET_LOADING',
        payload: false,
      });
      return null;
    }
    const currentBalance = response?.balance;
    const finalBalance = (response?.rawData?.final_balance / 100000000).toFixed(8);
    const displayBalance = Math.min(currentBalance, finalBalance);

    return displayBalance;
  } catch (error) {
    return null;
  }
}
