import { ethers } from 'ethers';

async function getTxnData({ provider, mnemonic, chainId }) {
  try {
    const wallet = ethers.Wallet.fromMnemonic(mnemonic);

    if (!mnemonic || !wallet?.address || !chainId) {
      const newError = new Error('Failed to send transaction');
      newError.ErrorCode = 60001;
      throw newError;
    }

    const connectedWallet = wallet.connect(provider);
    const latestNonce = await provider.getTransactionCount(wallet.address, 'latest');
    const feeData = await provider.getFeeData();

    // Safe fallback values for gas fees
    const maxFeePerGas = feeData.maxFeePerGas ?? ethers.utils.parseUnits('2', 'gwei');
    const maxPriorityFeePerGas =
      feeData.maxPriorityFeePerGas ?? ethers.utils.parseUnits('1', 'gwei');
    return {
      connectedWallet,
      latestNonce,
      maxFeePerGas,
      maxPriorityFeePerGas,
    };
  } catch (err) {
    if (err.ErrorCode) {
      throw err;
    }
    const error = new Error('Failed to generate transaction');
    throw error;
  }
}

export async function sendETHTransaction({ to, value, provider, mnemonic, chainId }) {
  try {
    const { connectedWallet, latestNonce, maxFeePerGas, maxPriorityFeePerGas } =
      await getTxnData({ to, value, provider, mnemonic, chainId });

    const amount = ethers.utils.parseEther(value).toString();
    let estimatedGasLimit;

    try {
      estimatedGasLimit = await provider.estimateGas({
        to,
        value: amount,
      });
    } catch (err) {
      const error = new Error('Failed to estimate gas limit');
      error.ErrorCode = 60003;
      throw error;
    }

    const tx = {
      to,
      value: amount,
      nonce: latestNonce,
      gasLimit: estimatedGasLimit,
      maxFeePerGas,
      maxPriorityFeePerGas,
      chainId: chainId,
      type: 2,
    };

    const signedTx = await connectedWallet.signTransaction(tx);
    const txResponse = await provider.sendTransaction(signedTx);

    return txResponse;
  } catch (err) {
    if (err.ErrorCode) {
      throw err;
    }
    const error = new Error('Failed to send transaction');
    throw error;
  }
}

export async function sendBTCTransaction({ to, value, mnemonic, network, from }) {
  try {
    const networkType = network?.name === 'sepolia' ? 'test3' : 'main';

    // Call the API endpoint to handle the transaction
    const response = await fetch('/api/coins/btcSendTxn', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        toAddress: to,
        amount: value,
        mnemonic: mnemonic,
        network: networkType,
        sourceAddress: from,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      const newError = new Error(data.error || 'Failed to send BTC transaction');
      newError.ErrorCode = 60005;
      throw newError;
    }

    return {
      success: true,
      hash: data.hash,
      network: data.network,
    };
  } catch (err) {
    if (err.ErrorCode) {
      throw err;
    }
    const error = new Error(`Failed to send BTC transaction: ${err.message}`);
    error.ErrorCode = 60005;
    throw error;
  }
}

export async function sendERCTransaction({
  to,
  value,
  provider,
  mnemonic,
  chainId,
  tokenAddress,
  decimals,
}) {
  try {
    const { connectedWallet, latestNonce, maxFeePerGas, maxPriorityFeePerGas } =
      await getTxnData({ to, value, provider, mnemonic, chainId, isERC: true });

    const amount = ethers.utils.parseUnits(value.toString(), decimals).toString();

    const erc20Interface = new ethers.utils.Interface([
      'function transfer(address to, uint256 amount) returns (bool)',
    ]);
    const contract = new ethers.Contract(tokenAddress, erc20Interface, connectedWallet);

    let estimatedGasLimit;
    try {
      estimatedGasLimit = await contract.estimateGas.transfer(to, amount);
      estimatedGasLimit = estimatedGasLimit.mul(120).div(100); // 20% buffer
      if (estimatedGasLimit.lt(ethers.BigNumber.from(70000))) {
        estimatedGasLimit = ethers.BigNumber.from(70000); // Force minimum gas limit
      }
    } catch (err) {
      const error = new Error('Failed to estimate gas limit for ERC20 transfer');
      error.ErrorCode = 60004;
      throw error;
    }

    const tx = await contract.transfer(to, amount, {
      nonce: latestNonce,
      gasLimit: estimatedGasLimit,
      maxFeePerGas,
      maxPriorityFeePerGas,
      type: 2,
    });

    const receipt = await tx.wait();

    return {
      hash: receipt.transactionHash,
      success: receipt.status === 1,
      blockNumber: receipt.blockNumber,
    };
  } catch (err) {
    if (err.ErrorCode) {
      throw err;
    }
    const error = new Error(`Failed to send ERC20 transaction: ${err.message}`);
    error.ErrorCode = 60005;
    throw error;
  }
}

export async function sendL2Transaction({ to, value, mnemonic, l2ChainInfo }) {
  try {
    const l2Provider = new ethers.providers.JsonRpcProvider(l2ChainInfo.rpcNodeUrl, {
      name: l2ChainInfo.chainName,
      chainId: Number(l2ChainInfo.chainId),
    });

    const wallet = ethers.Wallet.fromMnemonic(mnemonic);
    const connectedWallet = wallet.connect(l2Provider);
    const latestNonce = await l2Provider.getTransactionCount(wallet.address, 'latest');
    const feeData = await l2Provider.getFeeData();
    const gasPrice = feeData.gasPrice || ethers.utils.parseUnits('10', 'gwei'); // Fallback if null
    const amount = ethers.utils.parseUnits(value, l2ChainInfo.decimalPrecision);

    let estimatedGasLimit;
    try {
      estimatedGasLimit = await l2Provider.estimateGas({
        to,
        value: amount,
        from: wallet.address,
      });

      // Add 20% buffer to gas limit
      estimatedGasLimit = estimatedGasLimit.mul(120).div(100); // Add 20% buffer
    } catch (err) {
      const error = new Error('Failed to estimate gas limit on L2 network');
      error.ErrorCode = 60006;
      throw error;
    }

    const tx = {
      to,
      value: amount,
      nonce: latestNonce,
      gasLimit: estimatedGasLimit,
      gasPrice,
      chainId: Number(l2ChainInfo.chainId),
      type: 0, // Force legacy transaction type
    };
    const txResponse = await connectedWallet.sendTransaction(tx);

    const receipt = await txResponse.wait(1);

    return {
      hash: receipt.transactionHash,
      success: receipt.status === 1,
      network: l2ChainInfo.chainName,
    };
  } catch (err) {
    if (err.ErrorCode) {
      throw err;
    }
    const error = new Error(`Failed to send L2 transaction: ${err.message}`);
    error.ErrorCode = 60006;
    throw error;
  }
}

export async function getL2Fees({ amount, to, walletAddress, l2ChainInfo }) {
  const l2Provider = new ethers.providers.JsonRpcProvider(l2ChainInfo.rpcNodeUrl, {
    name: l2ChainInfo.chainName,
    chainId: Number(l2ChainInfo.chainId),
  });
  const latestNonce = await l2Provider.getTransactionCount(walletAddress, 'latest');
  const gasPrice = await l2Provider.getGasPrice();
  const amountToSend = ethers.utils.parseUnits(amount, l2ChainInfo.decimalPrecision);

  let estimatedGasLimit;
  try {
    estimatedGasLimit = await l2Provider.estimateGas({
      to,
      value: amountToSend,
      from: walletAddress,
      nonce: latestNonce,
      gasPrice,
    });

    const baseFee = estimatedGasLimit.mul(gasPrice);
    const fee = baseFee.mul(120).div(100); // Add 20% buffer
    const formattedFee = ethers.utils.formatUnits(fee, l2ChainInfo.decimalPrecision);
    return { coin: formattedFee, gasFeeLoading: false };
  } catch (err) {
    if (
      err?.code === 'UNPREDICTABLE_GAS_LIMIT' ||
      (err?.error?.body && err?.error?.body.includes('gas required exceeds allowance'))
    ) {
      const error = new Error('Insufficient funds');
      error.ErrorCode = 60004;
      throw error;
    }
    const error = new Error('Failed to estimate fees on L2 network');
    error.ErrorCode = 60006;
    throw error;
  }
}
