import { BigNumber } from 'ethers';
import { PoolKey } from '@uniswap/v4-sdk';

export type address = string;
export type bytes = string;

export const MAX_UINT48: BigNumber = BigNumber.from((1n << 48n) - 1n);

export enum Commands {
  // Masks to extract certain bits of commands
  FLAG_ALLOW_REVERT = 0x80,
  COMMAND_TYPE_MASK = 0x3f,

  // Command Types. Maximum supported command at this moment is 0x3f.
  // The commands are executed in nested if blocks to minimise gas consumption

  // Command Types where value<=0x07, executed in the first nested-if block
  V3_SWAP_EXACT_IN = 0x00,
  V3_SWAP_EXACT_OUT = 0x01,
  PERMIT2_TRANSFER_FROM = 0x02,
  PERMIT2_PERMIT_BATCH = 0x03,
  SWEEP = 0x04,
  TRANSFER = 0x05,
  PAY_PORTION = 0x06,
  // COMMAND_PLACEHOLDER = 0x07;

  // Command Types where 0x08<=value<=0x0f, executed in the second nested-if block
  V2_SWAP_EXACT_IN = 0x08,
  V2_SWAP_EXACT_OUT = 0x09,
  PERMIT2_PERMIT = 0x0a,
  WRAP_ETH = 0x0b,
  UNWRAP_WETH = 0x0c,
  PERMIT2_TRANSFER_FROM_BATCH = 0x0d,
  BALANCE_CHECK_ERC20 = 0x0e,
  // COMMAND_PLACEHOLDER = 0x0f;

  // Command Types where 0x10<=value<=0x20, executed in the third nested-if block
  V4_SWAP = 0x10,
  V3_POSITION_MANAGER_PERMIT = 0x11,
  V3_POSITION_MANAGER_CALL = 0x12,
  V4_INITIALIZE_POOL = 0x13,
  V4_POSITION_MANAGER_CALL = 0x14,
  // COMMAND_PLACEHOLDER = 0x15 -> 0x20

  // Command Types where 0x21<=value<=0x3f
  EXECUTE_SUB_PLAN = 0x21,
  // COMMAND_PLACEHOLDER for 0x22 to 0x3f
}

export enum Actions {
  INCREASE_LIQUIDITY = 0x00,
  DECREASE_LIQUIDITY = 0x01,
  MINT_POSITION = 0x02,
  BURN_POSITION = 0x03,
  INCREASE_LIQUIDITY_FROM_DELTAS = 0x04,
  MINT_POSITION_FROM_DELTAS = 0x05,
  SWAP_EXACT_IN_SINGLE = 0x06,
  SWAP_EXACT_IN = 0x07,
  SWAP_EXACT_OUT_SINGLE = 0x08,
  SWAP_EXACT_OUT = 0x09,
  DONATE = 0x0a,
  SETTLE = 0x0b,
  SETTLE_ALL = 0x0c,
  SETTLE_PAIR = 0x0d,
  TAKE = 0x0e,
  TAKE_ALL = 0x0f,
  TAKE_PORTION = 0x10,
  TAKE_PAIR = 0x11,
  CLOSE_CURRENCY = 0x12,
  CLEAR_OR_TAKE = 0x13,
  SWEEP = 0x14,
  WRAP = 0x15,
  UNWRAP = 0x16,
  MINT_6909 = 0x17,
  BURN_6909 = 0x18,
}

export type ExactInputSingleParams = {
  poolKey: PoolKey;
  zeroForOne: boolean;
  amountIn: BigNumber;
  amountOutMinimum: BigNumber;
  hookData: bytes;
};

export type PathKey = {
  intermediateCurrency: string;
  fee: BigNumber;
  tickSpacing: BigNumber;
  hooks: string;
  hookData: bytes;
};

export type ExactInputParams = {
  currencyIn: string;
  path: PathKey[];
  amountIn: BigNumber;
  amountOutMinimum: BigNumber;
};

export type PermitDetails = {
  // ERC20 token address
  token: address;
  // the maximum amount allowed to spend
  amount: BigNumber;
  // timestamp at which a spender's token allowances become invalid
  expiration: BigNumber;
  // an incrementing value indexed per owner,token,and spender for each signature
  nonce: BigNumber;
};

export type PermitSingle = {
  // the permit data for a single token alownce
  details: PermitDetails;
  // address permissioned on the allowed tokens
  spender: address;
  // deadline on the permit signature
  sigDeadline: BigNumber;
};
