import coreTheme, { colors as coreColors } from '@nerdcoresdk/core-brand-theme';
import localTheme, { colors as localColors } from './localTheme';
import { config } from 'config';
import { animations } from './animations';

const { brand } = config;
const useCoreTheme = brand.useCoreThemeRepo === 'true';
const theme = useCoreTheme ? coreTheme : localTheme;
const colors = useCoreTheme ? coreColors : localColors;

export default theme;
export { colors, animations };
