import crypto from 'crypto-browserify';
import { ethers } from 'ethers';
// Resources
import { getMemberSalts } from '@/resources/wallet-service.resource';
export const ERC20_ABI = [
  {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', type: 'uint256' }],
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', type: 'uint256' }],
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', type: 'bool' }],
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    type: 'function',
  },
];

// -----Getting and Decrypting mnemonic for signing transactions-----
export async function getEncryptedData(fetcher) {
  // get encrypted data from api
  return await fetcher(getMemberSalts())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
    });
}

export function createAesInstance(key, iv) {
  const keySize = 256;
  const blockSize = 128;
  const iterations = 1000;

  const derived = crypto.pbkdf2Sync(
    key,
    iv,
    iterations,
    (keySize + blockSize) / 8,
    'sha1'
  );

  return {
    key: derived.slice(0, keySize / 8),
    iv: derived.slice(keySize / 8),
  };
}

export function decryptMnemonic(encryptedData, passcode) {
  const cipher = encryptedData.encryptedMnemonic;
  const iv = encryptedData.salt64;

  try {
    const cipherBytes = Buffer.from(cipher, 'base64');
    const keyBytes = crypto
      .createHash('sha256')
      .update(Buffer.from(passcode, 'utf8'))
      .digest();
    const ivBytes = Buffer.from(iv, 'utf8');

    const { key: derivedKey, iv: derivedIV } = createAesInstance(keyBytes, ivBytes);

    const decipher = crypto.createDecipheriv('aes-256-cbc', derivedKey, derivedIV);
    let decrypted = decipher.update(cipherBytes);
    decrypted = Buffer.concat([decrypted, decipher.final()]);

    // Trim zero padding
    let lastZeroIndex = decrypted.length;
    for (let i = decrypted.length - 1; i >= 0; i--) {
      if (decrypted[i] === 0) {
        lastZeroIndex = i;
      } else {
        break;
      }
    }
    decrypted = decrypted.slice(0, lastZeroIndex);
    return decrypted.toString('utf8');
  } catch (err) {
    console.error(err);
    const error = new Error(
      'Decryption failed, please verify the passcode and try again.'
    );
    error.ErrorCode = 70001;
    throw error;
  }
}

// Network Token Configuration
export const initializeTokenConfig = (chainId) => {
  // Init values, replaced by db erc20 tokens call
  const baseConfig = {
    [chainId]: {
      tokens: {
        WETH: {
          address:
            chainId === 1
              ? '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2' // mainnet
              : '0xfff9976782d46cc05630d1f6ebab18b2324d6b14', // sepolia
          decimals: 18,
          symbol: 'WETH',
          name: 'Wrapped Ether',
        },
        USDC: {
          address:
            chainId === 1
              ? '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48' // mainnet
              : '0x039Cee696f61BC7Fa10ABB0f7C00EF2290374AA1', // sepolia
          decimals: 6,
          symbol: 'USDC',
          name: 'USD Coin',
        },
        IZE: {
          address:
            chainId === 1
              ? '0xf7e945FcE8F19302AaCc7E1418b0A0bdef89327B' // mainnet
              : '0x5d129408CDd79f90D6A416C41367867db7c9ff91', // sepolia
          decimals: 6,
          symbol: 'IZE',
          name: 'Galvan',
        },
        LACE: {
          address:
            chainId === 1
              ? '0xf7e945FcE8F19302AaCc7E1418b0A0bdef89327B' // mainnet IZE
              : '0xdFB76011eEd97Ef78A7637DA65fDbcAA584346F6', // sepolia
          decimals: 8,
          symbol: 'LACE',
          name: 'Lace',
        },
      },
    },
  };

  return baseConfig;
};

// Function to update config with ERC20 tokens from API
export const updateTokenConfig = (config, erc20Tokens) => {
  const newConfig = { ...config };

  erc20Tokens.forEach((token) => {
    const {
      chainId,
      symbol,
      contractAddress,
      decimalPrecision,
      name,
      allowPurchases,
      allowInApp,
      allowSell,
    } = token;

    if (!newConfig[chainId]) {
      newConfig[chainId] = { tokens: {} };
    }

    // Don't update WETH
    if (symbol !== 'WETH') {
      newConfig[chainId].tokens[symbol] = {
        address: contractAddress,
        decimals: decimalPrecision,
        symbol,
        name,
        allowPurchases,
        allowInApp,
        allowSell,
      };
    }
  });

  return newConfig;
};

export function calculateTokenAmount(amount, decimals) {
  try {
    const result = ethers.utils.parseUnits(amount.toString(), decimals);
    return result;
  } catch (err) {
    console.error('Error calculating token amount:', {
      error: err.message,
      input: amount,
      decimals,
    });
    throw err;
  }
}

export function formatTokenAmount(amount, decimals) {
  try {
    const result = ethers.utils.formatUnits(amount, decimals);
    return result;
  } catch (err) {
    console.error('Error formatting token amount:', {
      error: err.message,
      input: amount.toString(),
      decimals,
    });
    throw err;
  }
}

export async function getTokenQuote(fromToken, toToken, amount, provider) {
  try {
    // Create contract instances
    const tokenOutContract = new ethers.Contract(toToken.address, ERC20_ABI, provider);

    try {
      // Get balance using v5 syntax
      const balance = await tokenOutContract.balanceOf(provider.address);

      const amountIn = ethers.utils.parseUnits(amount.toString(), fromToken.decimals);

      const formattedQuote = ethers.utils.formatUnits(balance, toToken.decimals);

      return {
        amountIn,
        amountOut: balance,
        formattedQuote,
      };
    } catch (err) {
      console.error('Error getting token balance:', {
        error: err.message,
        code: err.code,
        tokenAddress: toToken.address,
        providerAddress: provider.address,
      });
      throw err;
    }
  } catch (err) {
    console.error('Fatal error in getTokenQuote:', {
      error: err.message,
      code: err.code,
      stack: err.stack,
    });
    throw err;
  }
}
