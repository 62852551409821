import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { colors } from '@/theme';

export default function PillButtonGroup({
  currentTab,
  onTabChange,
  visibleTabs,
  containerWidth = '250px',
  displayColors,
  buttonMargin,
}) {
  const buttonCount = visibleTabs.length;

  const getSliderPosition = (currentTab) => {
    const index = visibleTabs.findIndex((tab) => tab.value === currentTab);
    return `calc((${containerWidth} / ${buttonCount}) * ${index})`;
  };

  return (
    <Box
      id="pill-button-group"
      sx={{
        position: 'relative',
        background: displayColors?.sliderBackground || colors.surfaces.layerCard,
        borderRadius: '20px',
        margin: buttonMargin || '0 auto',
        width: containerWidth,
      }}
    >
      <Box
        id="pill-button-group-slider"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          backgroundColor: displayColors?.activeButton || colors.primary.main,
          color: displayColors?.inactiveButton || colors.surfaces.layerTwo,
          transition: 'transform 0.4s ease',
          zIndex: 0,
          borderRadius: '20px',
          width: `calc(100% / ${buttonCount})`,
          transform: `translateX(${getSliderPosition(currentTab)})`,
        }}
      />
      <ToggleButtonGroup
        id="toggleButtons"
        color="primary"
        value={currentTab}
        exclusive
        onChange={(e, newValue) => {
          if (newValue !== null) {
            onTabChange(newValue);
          }
        }}
        size="small"
        sx={{
          display: 'flex',
          position: 'relative',
          width: containerWidth,
          borderRadius: '20px',
          background: 'transparent',
          '& .MuiToggleButton-root': {
            color: displayColors?.inactiveText || colors.grey[700],
            textTransform: 'none',
            borderRadius: '20px',
            backgroundColor: 'transparent',
            border: 'none',
            boxShadow: 'none',
            flex: 1,
            zIndex: 1,
            padding: '3px 8px',
            width: `${100 / buttonCount}%`,
            '&.Mui-selected': {
              backgroundColor: 'transparent',
              color: displayColors?.activeText || colors.surfaces.layerTwo,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
            '&:not(.Mui-selected)': {
              backgroundColor: 'transparent',
            },
          },
        }}
      >
        {visibleTabs.map((tab) => (
          <ToggleButton value={tab.value} key={tab.value} size={'small'}>
            {tab.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
}
