import { ethers } from 'ethers';
import { ADDRESS_ZERO } from '@uniswap/router-sdk';
import { initialize, quoteSwap, swapSimple } from './index';
// Default Ethereum derivation path
export const DEFAULT_DERIVATION_PATH = "m/44'/60'/0'/0/0";

export interface UniswapSDKOptions {
  /**
   * JSON RPC URL for the Ethereum network
   */
  jsonRpcUrl: string;
  /**
   * Chain ID of the network to use
   */
  chainId: number;
  /**
   * Enable debug mode for verbose logging
   */
  debug?: boolean;
}

export interface QuoteParams {
  /**
   * The address of the token to swap from
   * Use ADDRESS_ZERO for native ETH
   */
  fromTokenAddress: string;
  /**
   * The address of the token to swap to
   * Use ADDRESS_ZERO for native ETH
   */
  toTokenAddress: string;
  /**
   * The amount of the from token to swap, as a string
   * For example, "1.0" for 1 token with 18 decimals
   */
  amountIn: string;
  /**
   * The slippage tolerance as a percentage (e.g., 0.5 for 0.5%)
   * Defaults to 0 (no slippage protection)
   */
  slippagePercentage?: number;
}

export interface SwapParams extends QuoteParams {
  /**
   * Mnemonic phrase (seed words) for the wallet performing the swap
   */
  mnemonic: string;
  /**
   * Optional derivation path for the mnemonic
   * If not provided, uses the default Ethereum path: "m/44'/60'/0'/0/0"
   */
  derivationPath?: string;
  /**
   * Minimum amount of the to token to receive
   * Defaults to 0 (no minimum)
   * If not provided and slippagePercentage is set, it will be calculated based on the quote
   */
  amountOutMinimum?: string;
  /**
   * Deadline for the swap in seconds
   * Defaults to max uint256 (no deadline)
   */
  deadline?: string;
}

export interface QuoteResult {
  /**
   * The protocol used for the swap (V2, V3, MIXED)
   */
  protocol: string;
  /**
   * The estimated amount of the to token to receive
   */
  estimatedAmountOut: string;
  /**
   * The minimum amount of the to token to receive based on slippage
   * Only returned if slippagePercentage is provided
   */
  amountOutMinimum?: string;
  /**
   * The estimated gas amount for the swap
   */
  estimatedGasAmount: string;
}

/**
 * UniswapSDK provides a simplified interface for interacting with Uniswap's Universal Router
 */
export class UniswapSDK {
  private initialized: boolean = false;
  private options: UniswapSDKOptions;

  /**
   * Create a new UniswapSDK instance
   * @param options Configuration options for the SDK
   */
  constructor(options: UniswapSDKOptions) {
    this.options = options;
  }

  /**
   * Initialize the SDK
   * This must be called before any other methods
   */
  public async init(): Promise<void> {
    if (this.initialized) return;

    await initialize(this.options.jsonRpcUrl, this.options.chainId, {
      isDebug: !!this.options.debug,
    });

    this.initialized = true;
  }

  /**
   * Get a wallet from a mnemonic phrase
   * @param mnemonic The mnemonic phrase (seed words)
   * @param derivationPath Optional derivation path, defaults to DEFAULT_DERIVATION_PATH
   * @param passphrase Optional BIP39 passphrase (sometimes called "13th word" or "seed extension")
   * @returns An ethers.js Wallet instance
   */
  private getWalletFromMnemonic(
    mnemonic: string,
    derivationPath?: string,
    passphrase?: string
  ): ethers.Wallet {
    const path = derivationPath || DEFAULT_DERIVATION_PATH;
    const provider = new ethers.providers.JsonRpcProvider(this.options.jsonRpcUrl);

    // Create an HD node from the mnemonic and passphrase
    const hdNode = ethers.utils.HDNode.fromMnemonic(mnemonic, passphrase);

    // Derive the wallet using the specified path
    const wallet = hdNode.derivePath(path);

    // Connect the wallet to the provider
    return new ethers.Wallet(wallet.privateKey, provider);
  }

  /**
   * Calculate the minimum amount out based on the estimated amount and slippage percentage
   * @param estimatedAmountOut The estimated amount out as a string
   * @param slippagePercentage The slippage percentage (e.g., 0.5 for 0.5%)
   * @returns The minimum amount out after applying slippage
   */
  private calculateAmountOutMinimum(
    estimatedAmountOut: string,
    slippagePercentage: number
  ): string {
    if (slippagePercentage < 0) {
      throw new Error('Slippage percentage cannot be negative');
    }

    // Parse the estimated amount out as a float
    const amountOut = parseFloat(estimatedAmountOut);

    // Calculate the minimum amount out with slippage
    // For example, with 0.5% slippage, multiply by 0.995
    const slippageFactor = (100 - slippagePercentage) / 100;
    const amountOutMinimum = amountOut * slippageFactor;

    // Determine the number of decimal places in the input
    const inputDecimalPlaces = (estimatedAmountOut.split('.')[1] || '').length;

    // Ensure we have at least 1 decimal place for whole numbers
    const decimalPlaces = inputDecimalPlaces === 0 ? 1 : inputDecimalPlaces;

    // Format the result with the appropriate number of decimal places
    return amountOutMinimum.toFixed(decimalPlaces);
  }

  /**
   * Get a quote for a swap
   * @param params Parameters for the quote
   * @returns Quote result or null if no quote is available
   */
  public async getQuote(params: QuoteParams): Promise<QuoteResult | null> {
    if (!this.initialized) {
      await this.init();
    }

    const result = await quoteSwap({
      currencyIn: params.fromTokenAddress,
      currencyOut: params.toTokenAddress,
      amountIn: params.amountIn,
    });

    if (!result) return null;

    const quoteResult: QuoteResult = {
      protocol: result.protocol,
      estimatedAmountOut: result.estimatedAmountOut,
      estimatedGasAmount: result.estimatedGasAmount.toString(),
    };

    // Calculate amountOutMinimum if slippagePercentage is provided
    if (params.slippagePercentage !== undefined && params.slippagePercentage > 0) {
      try {
        quoteResult.amountOutMinimum = this.calculateAmountOutMinimum(
          result.estimatedAmountOut,
          params.slippagePercentage
        );
      } catch (error) {
        console.error('Error calculating amountOutMinimum:', error);
      }
    }

    return quoteResult;
  }

  /**
   * Execute a swap
   * @param params Parameters for the swap
   * @returns The amount of the to token received as a string, or undefined if the swap failed
   */
  public async swap(
    params: SwapParams
  ): Promise<{ hash: string; amountOut: string } | undefined> {
    if (!this.initialized) {
      await this.init();
    }

    // If amountOutMinimum is not provided but slippagePercentage is, calculate it
    let amountOutMinimum = params.amountOutMinimum;
    if (
      !amountOutMinimum &&
      params.slippagePercentage !== undefined &&
      params.slippagePercentage > 0
    ) {
      const quote = await this.getQuote(params);
      if (quote && quote.amountOutMinimum) {
        amountOutMinimum = quote.amountOutMinimum;
      }
    }

    // Split the mnemonic into words and passphrase if present
    const words = params.mnemonic.split(' ');
    let mnemonic: string;
    let passphrase: string | undefined;

    if (words.length === 13) {
      // If there are 13 words, use the last one as the passphrase
      mnemonic = words.slice(0, 12).join(' ');
      passphrase = words[12];
    } else {
      mnemonic = params.mnemonic;
    }

    // Get wallet from mnemonic using the provided path or default, and passphrase if present
    const wallet = this.getWalletFromMnemonic(
      mnemonic,
      params.derivationPath,
      passphrase
    );

    const result = await swapSimple({
      privateKey: wallet.privateKey,
      currencyIn: params.fromTokenAddress,
      currencyOut: params.toTokenAddress,
      amountIn: params.amountIn,
      amountOutMinimum: amountOutMinimum,
      deadline: params.deadline,
    });

    return result ? { hash: result.hash, amountOut: result.amountOut } : undefined;
  }

  /**
   * Get the ADDRESS_ZERO constant for referring to native ETH
   */
  public static get NATIVE_ETH_ADDRESS(): string {
    return ADDRESS_ZERO;
  }
}
