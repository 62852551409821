import { keyframes } from '@mui/material/styles';

const rotations = {
  clockwise: keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `,

  counterClockwise: keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  `,
};

const fade = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
`;

export const animations = {
  rotations,
  fade,
};
