import { BigNumber, ethers } from 'ethers';
import {
  Currency,
  CurrencyAmount,
  NativeCurrency,
  Token,
  TradeType,
} from '@uniswap/sdk-core';
import { FeeAmount, Pool as V3Pool } from '@uniswap/v3-sdk';
import { Pool as V4Pool } from '@uniswap/v4-sdk';
import { ADDRESS_ZERO, Protocol } from '@uniswap/router-sdk';
export { ADDRESS_ZERO } from '@uniswap/router-sdk';
import {
  AlphaRouter,
  MixedRoute,
  nativeOnChain,
  V2Route,
  V3Route,
  V4Route,
} from '@uniswap/smart-order-router';
import {
  address,
  bytes,
  Actions,
  Commands,
  ExactInputParams,
  ExactInputSingleParams,
  MAX_UINT48,
  PathKey,
  PermitSingle,
} from './types';
import { NETWORK_ADDRESSES } from './constants';
import { UniversalRouter_ABI, Permit2_ABI, ERC20_ABI } from './abi';

export let provider: ethers.providers.JsonRpcProvider;
export let network: ethers.providers.Network;
export let alphaRouter: AlphaRouter;
export let nativeCurrency: NativeCurrency;
export let universalRouter: ethers.Contract;
export let permit2: ethers.Contract;
export let isDebug: boolean = false;

export const abiCoder = ethers.utils.defaultAbiCoder;

// Helper for BigNumber zero value
const ZERO = BigNumber.from('0');

export async function initialize(
  jsonRpcUrl: string,
  chainId: number,
  options: { isDebug: boolean } = { isDebug: false }
) {
  provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl);

  network = await provider.getNetwork();

  if (!NETWORK_ADDRESSES[chainId]) {
    throw new Error(`Network with chainId ${chainId} is not supported`);
  }

  const addresses = NETWORK_ADDRESSES[chainId];

  alphaRouter = new AlphaRouter({
    chainId: network.chainId,
    provider,
  });

  nativeCurrency = nativeOnChain(network.chainId);

  universalRouter = new ethers.Contract(
    addresses.universalRouterAddress,
    UniversalRouter_ABI,
    provider
  );
  permit2 = new ethers.Contract(addresses.permit2Address, Permit2_ABI, provider);

  isDebug = options.isDebug;
}

export function getCurrencyAddress(currency: Currency) {
  if (currency.isNative) return ADDRESS_ZERO;
  return (currency as Token).address;
}

export function compareCurrency(currencyA: Currency, currencyB: Currency) {
  if (currencyA.isNative && currencyB.isNative) {
    return true;
  }

  if (!currencyA.isNative && !currencyB.isNative) {
    if (getCurrencyAddress(currencyA) === getCurrencyAddress(currencyB)) {
      return true;
    }
  }

  return false;
}

export function compareCurrencyIgnoreWrapped(currencyA: Currency, currencyB: Currency) {
  if (currencyA.isNative && !currencyB.isNative) {
    return compareCurrency(nativeCurrency.wrapped, currencyB);
  }

  if (!currencyA.isNative && currencyB.isNative) {
    return compareCurrency(currencyA, nativeCurrency.wrapped);
  }

  return compareCurrency(currencyA, currencyB);
}

export async function quoteSwap({
  currencyIn,
  currencyOut,
  amountIn,
}: {
  currencyIn: address;
  currencyOut: address;
  amountIn: string;
}) {
  let _currencyIn: Currency;
  let _currencyOut: Currency;
  let _amountIn: BigNumber;

  if (currencyIn === ADDRESS_ZERO) {
    _currencyIn = nativeCurrency;
    _amountIn = ethers.utils.parseEther(amountIn);
  } else {
    const inContract = new ethers.Contract(currencyIn, ERC20_ABI, provider);
    const inDecimals: number = await inContract.decimals();
    _currencyIn = new Token(network.chainId, currencyIn, inDecimals);
    _amountIn = ethers.utils.parseUnits(amountIn, inDecimals);
  }

  if (currencyOut === ADDRESS_ZERO) {
    _currencyOut = nativeCurrency;
  } else {
    const outContract = new ethers.Contract(currencyOut, ERC20_ABI, provider);
    const outDecimals: number = await outContract.decimals();
    _currencyOut = new Token(network.chainId, currencyOut, outDecimals);
  }

  const swapRoute = await getSwapRoute({
    currencyIn: _currencyIn,
    currencyOut: _currencyOut,
    amountIn: _amountIn,
  });

  if (!swapRoute || !swapRoute.optimizedSwapRoute) {
    return console.error("Can't find route");
  }

  let estimatedAmountOut: string;

  if (currencyOut === ADDRESS_ZERO) {
    estimatedAmountOut = ethers.utils.formatEther(swapRoute.estimatedAmountOut);
  } else {
    const outContract = new ethers.Contract(currencyOut, ERC20_ABI, provider);
    const outDecimals: number = await outContract.decimals();
    estimatedAmountOut = ethers.utils.formatUnits(
      swapRoute.estimatedAmountOut,
      outDecimals
    );
  }

  return {
    protocol: swapRoute.optimizedSwapRoute.protocol,
    estimatedAmountOut,
    estimatedGasAmount: swapRoute.estimatedGasAmount,
  };
}

export async function swapSimple({
  privateKey,
  currencyIn,
  currencyOut,
  amountIn,
  amountOutMinimum = '0',
  deadline = ethers.constants.MaxUint256.toString(),
}: {
  privateKey: string;
  currencyIn: address;
  currencyOut: address;
  amountIn: string;
  amountOutMinimum?: string;
  deadline?: string;
}): Promise<{ hash: string; amountOut: string } | void> {
  if (currencyIn === currencyOut) {
    return console.error(
      "Input currency address and output currency address can't be the same"
    );
  }

  const signer = new ethers.Wallet(privateKey, provider);

  let _currencyIn: Currency;
  let _currencyOut: Currency;
  let _amountIn: BigNumber;
  let _amountOutMinimum: BigNumber;
  const _deadline: BigNumber = BigNumber.from(deadline);

  if (currencyIn === ADDRESS_ZERO) {
    _currencyIn = nativeCurrency;
    _amountIn = ethers.utils.parseEther(amountIn);
  } else {
    const inContract = new ethers.Contract(currencyIn, ERC20_ABI, signer);
    const inDecimals: number = await inContract.decimals();
    _currencyIn = new Token(network.chainId, currencyIn, inDecimals);
    _amountIn = ethers.utils.parseUnits(amountIn, inDecimals);
  }

  if (currencyOut === ADDRESS_ZERO) {
    _currencyOut = nativeCurrency;
    _amountOutMinimum = ethers.utils.parseEther(amountOutMinimum);
  } else {
    const outContract = new ethers.Contract(currencyOut, ERC20_ABI, signer);
    const outDecimals: number = await outContract.decimals();
    _currencyOut = new Token(network.chainId, currencyOut, outDecimals);
    _amountOutMinimum = ethers.utils.parseUnits(amountOutMinimum, outDecimals);
  }

  const _amountOut = await swap({
    signer,
    currencyIn: _currencyIn,
    currencyOut: _currencyOut,
    amountIn: _amountIn,
    amountOutMinimum: _amountOutMinimum,
    deadline: _deadline,
  });

  if (!_amountOut || !_amountOut.amountOut) {
    return console.error('Swap failed');
  }

  let amountOut: string;

  if (currencyOut === ADDRESS_ZERO) {
    amountOut = ethers.utils.formatEther(_amountOut.amountOut);
  } else {
    const outContract = new ethers.Contract(currencyOut, ERC20_ABI, signer);
    const outDecimals: number = await outContract.decimals();
    amountOut = ethers.utils.formatUnits(_amountOut.amountOut, outDecimals);
  }

  return {
    hash: _amountOut.hash,
    amountOut,
  };
}

export async function getSwapRoute({
  currencyIn,
  currencyOut,
  amountIn,
}: {
  currencyIn: Currency;
  currencyOut: Currency;
  amountIn: BigNumber;
}) {
  if (compareCurrencyIgnoreWrapped(currencyIn, currencyOut)) {
    return console.error("Input currency and output currency can't be the same");
  }

  const amount = CurrencyAmount.fromRawAmount(currencyIn, amountIn.toString());

  const swapRoute = await alphaRouter.route(
    amount,
    currencyOut,
    TradeType.EXACT_INPUT,
    undefined,
    {
      protocols: [Protocol.V3, Protocol.V4],
    }
  );

  if (!swapRoute) {
    return console.error("Can't find swap route");
  }

  let estimatedAmountOut = ZERO;
  let estimatedGasAmount = ZERO;
  let optimizedSwapRoute: V2Route | V3Route | V4Route | MixedRoute | undefined =
    undefined;

  for (const route of swapRoute.route) {
    if (route.rawQuote.gt(estimatedAmountOut)) {
      estimatedAmountOut = route.rawQuote;
      estimatedGasAmount = route.gasEstimate;
      optimizedSwapRoute = route.route;
    }
  }

  return {
    estimatedAmountOut,
    estimatedGasAmount,
    optimizedSwapRoute,
  };
}

export async function swap({
  signer,
  currencyIn,
  currencyOut,
  amountIn,
  amountOutMinimum = ZERO,
  deadline = ethers.constants.MaxUint256,
}: {
  signer: ethers.Wallet;
  currencyIn: Currency;
  currencyOut: Currency;
  amountIn: BigNumber;
  amountOutMinimum?: BigNumber;
  deadline?: BigNumber;
}): Promise<{ hash: string; amountOut: BigNumber } | void> {
  if (isDebug) {
    console.info(
      `swap(signer = ${signer.address}, currencyIn = ${getCurrencyAddress(currencyIn)}, currencyOut = ${getCurrencyAddress(currencyOut)}, amountIn = ${amountIn})`
    );
  }

  const swapRoute = await getSwapRoute({
    currencyIn,
    currencyOut,
    amountIn,
  });

  if (!swapRoute || !swapRoute.optimizedSwapRoute) {
    return console.error("Can't find route");
  }

  if (swapRoute.optimizedSwapRoute.protocol === Protocol.V3) {
    const path = encodeV3Path({
      currencyIn,
      currencyOut,
      pools: swapRoute.optimizedSwapRoute.pools,
    });

    if (!path) {
      return console.error("Can't encode V3 path");
    }

    return swapV3ExactIn({
      signer,
      currencyIn,
      currencyOut,
      amountIn,
      amountOutMinimum,
      path,
    });
  } else if (swapRoute.optimizedSwapRoute.protocol === Protocol.V4) {
    const path = getV4Path({
      currencyIn,
      currencyOut,
      pools: swapRoute.optimizedSwapRoute.pools,
    });

    if (!path) {
      return console.error("Can't get V4 path");
    }

    const swapParams = {
      currencyIn: getCurrencyAddress(currencyIn),
      path,
      amountIn,
      amountOutMinimum,
    };

    return swapV4ExactIn({
      signer,
      swapParams,
      deadline,
    });
  }
}

export function encodeV3Path({
  currencyIn,
  currencyOut,
  pools,
}: {
  currencyIn: Currency;
  currencyOut: Currency;
  pools: V3Pool[];
}) {
  const types: address[] = [];
  const values: (address | FeeAmount)[] = [];

  let nextTokenIn = currencyIn.isNative ? nativeCurrency.wrapped : currencyIn;

  for (const pool of pools) {
    types.push('address', 'uint24');
    values.push(
      nextTokenIn.isNative
        ? nativeCurrency.wrapped.address
        : (nextTokenIn as Token).address,
      pool.fee
    );

    if (compareCurrencyIgnoreWrapped(nextTokenIn, pool.token0)) {
      nextTokenIn = pool.token1;
    } else if (compareCurrencyIgnoreWrapped(nextTokenIn, pool.token1)) {
      nextTokenIn = pool.token0;
    } else {
      return console.error('Invalid V3 path');
    }
  }

  if (!compareCurrencyIgnoreWrapped(nextTokenIn, currencyOut)) {
    return console.error('Invalid V3 path');
  }

  types.push('address');
  values.push(
    nextTokenIn.isNative ? nativeCurrency.wrapped.address : (nextTokenIn as Token).address
  );

  return ethers.utils.solidityPack(types, values);
}

export function getV4Path({
  currencyIn,
  currencyOut,
  pools,
}: {
  currencyIn: Currency;
  currencyOut: Currency;
  pools: V4Pool[];
}) {
  let intermediateCurrency = currencyIn;

  const path: PathKey[] = [];

  for (const pool of pools) {
    if (compareCurrencyIgnoreWrapped(intermediateCurrency, pool.currency0)) {
      intermediateCurrency = pool.currency1;
    } else if (compareCurrencyIgnoreWrapped(intermediateCurrency, pool.currency1)) {
      intermediateCurrency = pool.currency0;
    } else {
      return console.error('Invalid V4 path');
    }

    path.push({
      intermediateCurrency: getCurrencyAddress(intermediateCurrency),
      fee: BigNumber.from(pool.fee),
      tickSpacing: BigNumber.from(pool.tickSpacing),
      hooks: pool.hooks,
      hookData: '0x',
    });
  }

  if (!compareCurrencyIgnoreWrapped(intermediateCurrency, currencyOut)) {
    return console.error('Invalid V4 path');
  }

  return path;
}

export async function generatePermit2Signature(
  signer: ethers.Wallet,
  types: Record<string, Array<ethers.TypedDataField>>,
  value: Record<string, unknown>
) {
  const domain: ethers.TypedDataDomain = {
    name: 'Permit2',
    chainId: network.chainId,
    verifyingContract: permit2.address,
  };

  return await signer._signTypedData(domain, types, value);
}

export async function swapV3ExactIn({
  signer,
  currencyIn,
  currencyOut,
  path,
  amountIn,
  amountOutMinimum = ZERO,
  deadline = ethers.constants.MaxUint256,
}: {
  signer: ethers.Wallet;
  currencyIn: Currency;
  currencyOut: Currency;
  path: bytes;
  amountIn: BigNumber;
  amountOutMinimum?: BigNumber;
  deadline?: BigNumber;
}): Promise<{ hash: string; amountOut: BigNumber } | void> {
  if (isDebug) {
    console.info(
      `swapV3ExactIn(signer = ${signer.address}, currencyIn = ${getCurrencyAddress(currencyIn)}, currencyOut = ${getCurrencyAddress(currencyOut)}, path = ${path}, amountIn = ${amountIn})`
    );
  }

  const commandTypes: string[] = [];
  const commandValues: Commands[] = [];

  const inputs: string[] = [];

  if (currencyIn.isNative) {
    commandTypes.push('uint8');
    commandValues.push(Commands.WRAP_ETH);

    inputs.push(
      abiCoder.encode(['address', 'uint256'], [universalRouter.address, amountIn])
    );
  } else {
    commandTypes.push('uint8');
    commandValues.push(Commands.PERMIT2_PERMIT);

    const [, , nonce] = await permit2.allowance(
      signer.address,
      currencyIn.isNative ? ADDRESS_ZERO : (currencyIn as Token).address,
      universalRouter.address
    );

    const types = {
      PermitDetails: [
        { name: 'token', type: 'address' },
        { name: 'amount', type: 'uint160' },
        { name: 'expiration', type: 'uint48' },
        { name: 'nonce', type: 'uint48' },
      ],
      PermitSingle: [
        { name: 'details', type: 'PermitDetails' },
        { name: 'spender', type: 'address' },
        { name: 'sigDeadline', type: 'uint256' },
      ],
    };

    const permitSingle: PermitSingle = {
      details: {
        token: getCurrencyAddress(currencyIn),
        amount: amountIn,
        expiration: MAX_UINT48,
        nonce,
      },
      spender: universalRouter.address,
      sigDeadline: ethers.constants.MaxUint256,
    };

    const permit2Signature = await generatePermit2Signature(signer, types, permitSingle);

    if (!permit2Signature) {
      return console.error("Can't generate Permit2 signature");
    }

    inputs.push(
      abiCoder.encode(
        [
          'tuple(tuple(address token, uint160 amount, uint48 expiration, uint48 nonce) details, address spender, uint256 sigDeadline)',
          'bytes',
        ],
        [permitSingle, permit2Signature]
      )
    );
  }

  {
    commandTypes.push('uint8');
    commandValues.push(Commands.V3_SWAP_EXACT_IN);

    inputs.push(
      abiCoder.encode(
        ['address', 'uint256', 'uint256', 'bytes', 'bool'],
        [
          currencyOut.isToken ? signer.address : universalRouter.address,
          amountIn,
          amountOutMinimum,
          path,
          currencyIn.isToken,
        ]
      )
    );
  }

  if (currencyOut.isNative) {
    commandTypes.push('uint8');
    commandValues.push(Commands.UNWRAP_WETH);

    inputs.push(abiCoder.encode(['address', 'uint256'], [signer.address, ZERO]));
  }

  const tokenIn = new ethers.Contract(
    currencyIn.isNative ? nativeCurrency.wrapped.address : (currencyIn as Token).address,
    ERC20_ABI,
    signer
  );
  const tokenOut = new ethers.Contract(
    currencyOut.isNative
      ? nativeCurrency.wrapped.address
      : (currencyOut as Token).address,
    ERC20_ABI,
    signer
  );

  if (currencyIn.isNative) {
    const ethBalance: BigNumber = await provider.getBalance(signer.address);

    if (ethBalance.lt(amountIn)) {
      return console.error('Insufficient ETH balance');
    }
  } else {
    const tokenBalance: BigNumber = await tokenIn.balanceOf(signer.address);

    if (tokenBalance.lt(amountIn)) {
      return console.error('Insufficient token balance');
    }

    const tokenAllowance: BigNumber = await tokenIn.allowance(
      signer.address,
      permit2.address
    );

    if (tokenAllowance.lt(amountIn)) {
      const tokenApproveResponse = await tokenIn.approve(
        permit2.address,
        ethers.constants.MaxUint256
      );
      await tokenApproveResponse.wait();
    }
  }

  let beforeBalance: BigNumber;
  if (currencyOut.isNative) {
    beforeBalance = await provider.getBalance(signer.address);
  } else {
    beforeBalance = await tokenOut.balanceOf(signer.address);
  }

  const commands = ethers.utils.solidityPack(commandTypes, commandValues);

  const executeTx = await execute({
    signer,
    commands,
    inputs,
    deadline,
    value: currencyIn.isNative ? amountIn : ZERO,
  });

  if (!executeTx) {
    return console.error('execute failed');
  }

  let afterBalance: BigNumber;
  if (currencyOut.isNative) {
    afterBalance = await provider.getBalance(signer.address);
  } else {
    afterBalance = await tokenOut.balanceOf(signer.address);
  }

  const amountOut = afterBalance
    .sub(beforeBalance)
    .add(
      currencyOut.isNative ? executeTx.effectiveGasPrice.mul(executeTx.gasUsed) : ZERO
    );

  return {
    hash: executeTx.transactionHash,
    amountOut,
  };
}

export async function swapV4ExactInSingle({
  signer,
  swapParams,
  deadline = ethers.constants.MaxUint256,
}: {
  signer: ethers.Wallet;
  swapParams: ExactInputSingleParams;
  deadline?: BigNumber;
}): Promise<{ hash: string; amountOut: BigNumber } | void> {
  if (isDebug) {
    console.info(
      `swapV4ExactInSingle(signer = ${signer.address}, swapParams = ${JSON.stringify(swapParams)})`
    );
  }

  const [currencyIn, currencyOut]: [currencyIn: address, currencyOut: address] =
    swapParams.zeroForOne
      ? [swapParams.poolKey.currency0, swapParams.poolKey.currency1]
      : [swapParams.poolKey.currency1, swapParams.poolKey.currency0];

  const [amountIn, amountOutMinimum]: [amountIn: BigNumber, amountOutMinimum: BigNumber] =
    [swapParams.amountIn, swapParams.amountOutMinimum];

  const commandTypes: string[] = [];
  const commandValues: Commands[] = [];

  const inputs: string[] = [];

  if (currencyIn !== ADDRESS_ZERO) {
    commandTypes.push('uint8');
    commandValues.push(Commands.PERMIT2_PERMIT);

    const [, , nonce] = await permit2.allowance(
      signer.address,
      currencyIn,
      universalRouter.address
    );

    const types = {
      PermitDetails: [
        { name: 'token', type: 'address' },
        { name: 'amount', type: 'uint160' },
        { name: 'expiration', type: 'uint48' },
        { name: 'nonce', type: 'uint48' },
      ],
      PermitSingle: [
        { name: 'details', type: 'PermitDetails' },
        { name: 'spender', type: 'address' },
        { name: 'sigDeadline', type: 'uint256' },
      ],
    };

    const permitSingle: PermitSingle = {
      details: {
        token: currencyIn,
        amount: amountIn,
        expiration: MAX_UINT48,
        nonce,
      },
      spender: universalRouter.address,
      sigDeadline: ethers.constants.MaxUint256,
    };

    const permit2Signature = await generatePermit2Signature(signer, types, permitSingle);

    if (!permit2Signature) {
      return console.error("Can't generate Permit2 signature");
    }

    inputs.push(
      abiCoder.encode(
        [
          'tuple(tuple(address token, uint160 amount, uint48 expiration, uint48 nonce) details, address spender, uint256 sigDeadline)',
          'bytes',
        ],
        [permitSingle, permit2Signature]
      )
    );
  }

  {
    commandTypes.push('uint8');
    commandValues.push(Commands.V4_SWAP);

    const actions: bytes = ethers.utils.solidityPack(
      ['uint8', 'uint8', 'uint8'],
      [Actions.SWAP_EXACT_IN_SINGLE, Actions.SETTLE_ALL, Actions.TAKE_ALL]
    );

    const params: bytes[] = new Array(3);
    params[0] = abiCoder.encode(
      [
        'tuple(tuple(address currency0, address currency1, uint24 fee, int24 tickSpacing, address hooks) poolKey, bool zeroForOne, uint128 amountIn, uint128 amountOutMinimum, bytes hookData)',
      ],
      [swapParams]
    );
    params[1] = abiCoder.encode(['address', 'uint128'], [currencyIn, amountIn]);
    params[2] = abiCoder.encode(['address', 'uint128'], [currencyOut, amountOutMinimum]);

    inputs.push(abiCoder.encode(['bytes', 'bytes[]'], [actions, params]));
  }

  const tokenIn = new ethers.Contract(currencyIn, ERC20_ABI, signer);
  const tokenOut = new ethers.Contract(currencyOut, ERC20_ABI, signer);

  if (currencyIn === ADDRESS_ZERO) {
    const ethBalance: BigNumber = await provider.getBalance(signer.address);

    if (ethBalance.lt(amountIn)) {
      return console.error('Insufficient ETH balance');
    }
  } else {
    const tokenBalance: BigNumber = await tokenIn.balanceOf(signer.address);

    if (tokenBalance.lt(amountIn)) {
      return console.error('Insufficient token balance');
    }

    const tokenAllowance: BigNumber = await tokenIn.allowance(
      signer.address,
      permit2.address
    );

    if (tokenAllowance.lt(amountIn)) {
      const tokenApproveResponse = await tokenIn.approve(
        permit2.address,
        ethers.constants.MaxUint256
      );
      await tokenApproveResponse.wait();
    }
  }

  let beforeBalance: BigNumber;
  if (currencyOut === ADDRESS_ZERO) {
    beforeBalance = await provider.getBalance(signer.address);
  } else {
    beforeBalance = await tokenOut.balanceOf(signer.address);
  }

  const commands: bytes = ethers.utils.solidityPack(commandTypes, commandValues);

  const executeTx = await execute({
    signer,
    commands,
    inputs,
    deadline,
    value: currencyIn === ADDRESS_ZERO ? swapParams.amountIn : BigNumber.from(0n),
  });

  if (!executeTx) {
    return console.error('execute failed');
  }

  let afterBalance: BigNumber;
  if (currencyOut === ADDRESS_ZERO) {
    afterBalance = await provider.getBalance(signer.address);
  } else {
    afterBalance = await tokenOut.balanceOf(signer.address);
  }

  const amountOut = afterBalance
    .sub(beforeBalance)
    .add(
      currencyOut === ADDRESS_ZERO
        ? executeTx.effectiveGasPrice.mul(executeTx.gasUsed)
        : BigNumber.from(0n)
    );

  return {
    hash: executeTx.transactionHash,
    amountOut,
  };
}

export async function swapV4ExactIn({
  signer,
  swapParams,
  deadline = ethers.constants.MaxUint256,
}: {
  signer: ethers.Wallet;
  swapParams: ExactInputParams;
  deadline?: BigNumber;
}): Promise<{ hash: string; amountOut: BigNumber } | void> {
  if (isDebug) {
    console.info(
      `swapV4ExactIn(signer = ${signer.address}, swapParams = ${JSON.stringify(swapParams)})`
    );
  }

  const pathOut: PathKey = swapParams.path[swapParams.path.length - 1];

  const [currencyIn, currencyOut]: [currencyIn: address, currencyOut: address] = [
    swapParams.currencyIn,
    pathOut.intermediateCurrency,
  ];

  const [amountIn, amountOutMinimum]: [amountIn: BigNumber, amountOutMinimum: BigNumber] =
    [swapParams.amountIn, swapParams.amountOutMinimum];

  const commandTypes: string[] = [];
  const commandValues: Commands[] = [];

  const inputs: string[] = [];

  if (currencyIn !== ADDRESS_ZERO) {
    commandTypes.push('uint8');
    commandValues.push(Commands.PERMIT2_PERMIT);

    const [, , nonce] = await permit2.allowance(
      signer.address,
      currencyIn === ADDRESS_ZERO ? ADDRESS_ZERO : currencyIn,
      universalRouter.address
    );

    const types = {
      PermitDetails: [
        { name: 'token', type: 'address' },
        { name: 'amount', type: 'uint160' },
        { name: 'expiration', type: 'uint48' },
        { name: 'nonce', type: 'uint48' },
      ],
      PermitSingle: [
        { name: 'details', type: 'PermitDetails' },
        { name: 'spender', type: 'address' },
        { name: 'sigDeadline', type: 'uint256' },
      ],
    };

    const permitSingle: PermitSingle = {
      details: {
        token: currencyIn,
        amount: amountIn,
        expiration: MAX_UINT48,
        nonce,
      },
      spender: universalRouter.address,
      sigDeadline: ethers.constants.MaxUint256,
    };

    const permit2Signature = await generatePermit2Signature(signer, types, permitSingle);

    if (!permit2Signature) {
      return console.error("Can't generate Permit2 signature");
    }

    inputs.push(
      abiCoder.encode(
        [
          'tuple(tuple(address token, uint160 amount, uint48 expiration, uint48 nonce) details, address spender, uint256 sigDeadline)',
          'bytes',
        ],
        [permitSingle, permit2Signature]
      )
    );
  }

  {
    commandTypes.push('uint8');
    commandValues.push(Commands.V4_SWAP);

    const actions: bytes = ethers.utils.solidityPack(
      ['uint8', 'uint8', 'uint8'],
      [Actions.SWAP_EXACT_IN, Actions.SETTLE_ALL, Actions.TAKE_ALL]
    );

    const params: bytes[] = new Array(3);
    params[0] = abiCoder.encode(
      [
        'tuple(address currencyIn, tuple(address intermediateCurrency, uint24 fee, int24 tickSpacing, address hooks, bytes hookData)[] path, uint128 amountIn, uint128 amountOutMinimum)',
      ],
      [swapParams]
    );
    params[1] = abiCoder.encode(['address', 'uint128'], [currencyIn, amountIn]);
    params[2] = abiCoder.encode(['address', 'uint128'], [currencyOut, amountOutMinimum]);

    inputs.push(abiCoder.encode(['bytes', 'bytes[]'], [actions, params]));
  }

  const tokenIn = new ethers.Contract(currencyIn, ERC20_ABI, signer);
  const tokenOut = new ethers.Contract(currencyOut, ERC20_ABI, signer);

  if (currencyIn === ADDRESS_ZERO) {
    const ethBalance: BigNumber = await provider.getBalance(signer.address);

    if (ethBalance.lt(amountIn)) {
      return console.error('Insufficient ETH balance');
    }
  } else {
    const tokenBalance: BigNumber = await tokenIn.balanceOf(signer.address);

    if (tokenBalance.lt(amountIn)) {
      return console.error('Insufficient token balance');
    }

    const tokenAllowance: BigNumber = await tokenIn.allowance(
      signer.address,
      permit2.address
    );

    if (tokenAllowance.lt(amountIn)) {
      const tokenApproveResponse = await tokenIn.approve(
        permit2.address,
        ethers.constants.MaxUint256
      );
      await tokenApproveResponse.wait();
    }
  }

  let beforeBalance: BigNumber;
  if (currencyOut === ADDRESS_ZERO) {
    beforeBalance = await provider.getBalance(signer.address);
  } else {
    beforeBalance = await tokenOut.balanceOf(signer.address);
  }

  const commands: bytes = ethers.utils.solidityPack(commandTypes, commandValues);

  const executeTx = await execute({
    signer,
    commands,
    inputs,
    deadline,
    value: currencyIn === ADDRESS_ZERO ? amountIn : BigNumber.from(0n),
  });

  if (!executeTx) {
    return console.error('execute failed');
  }

  let afterBalance: BigNumber;
  if (currencyOut === ADDRESS_ZERO) {
    afterBalance = await provider.getBalance(signer.address);
  } else {
    afterBalance = await tokenOut.balanceOf(signer.address);
  }

  const amountOut = afterBalance
    .sub(beforeBalance)
    .add(
      currencyOut === ADDRESS_ZERO
        ? executeTx.effectiveGasPrice.mul(executeTx.gasUsed)
        : BigNumber.from(0n)
    );

  return {
    hash: executeTx.transactionHash,
    amountOut,
  };
}

export async function execute({
  signer,
  commands,
  inputs,
  deadline = ethers.constants.MaxUint256,
  value = BigNumber.from(0n),
}: {
  signer: ethers.Wallet;
  commands: bytes;
  inputs: bytes[];
  deadline?: BigNumber;
  value?: BigNumber;
}) {
  if (isDebug) {
    console.info(
      `execute(signer = ${signer.address}, commands = ${commands}, inputs = ${inputs})`
    );
  }

  let gasLimit: BigNumber = BigNumber.from(1_000_000n);

  try {
    // First try static call to validate the transaction
    await universalRouter.callStatic['execute(bytes,bytes[],uint256)'](
      commands,
      inputs,
      deadline,
      {
        value,
        from: signer.address,
      }
    );

    // If static call succeeds, try gas estimation with more context
    const estimatedGasLimit = await provider.estimateGas({
      to: universalRouter.address,
      from: signer.address,
      data: universalRouter.interface.encodeFunctionData(
        'execute(bytes,bytes[],uint256)',
        [commands, inputs, deadline]
      ),
      value,
    });

    const block = await provider.getBlock('latest');

    if (estimatedGasLimit.gt(block.gasLimit)) {
      return console.error('Exceed maximum gas limit');
    }

    gasLimit = estimatedGasLimit.mul(2);

    if (gasLimit.gt(block.gasLimit)) {
      gasLimit = block.gasLimit;
    }

    console.info('Gas estimation succeeded:', {
      estimatedGas: estimatedGasLimit.toString(),
      finalGasLimit: gasLimit.toString(),
      blockGasLimit: block.gasLimit.toString(),
    });
  } catch (error: unknown) {
    console.error('Gas estimation failed.');
  }
  const executeResponse = await universalRouter
    .connect(signer)
    ['execute(bytes,bytes[],uint256)'](commands, inputs, deadline, {
      value,
      gasLimit,
    });
  const executeTx = await executeResponse.wait();

  return executeTx;
}

// Export the SDK
export * from './sdk';
