import { Box } from '@mui/material';
import { OnHoverToolTip } from '@/common/index';
import { greaterThanPrecision, formatAmount } from '@/utils/index';
import { truncateWithoutRounding } from '@/utils/coin';

const TokenAmount = ({
  amount,
  tipPlacement = 'top',
  token,
  precision,
  isNegative = false,
  align = 'flex-start',
}) => {
  const displayAmount = Number(amount);
  const displayPrecision = token === 'USDC' ? 2 : precision || 6;
  const decimalPrecision = amount?.toString().split('.')[1]?.length || displayPrecision;

  return (
    <Box
      component="span"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: align }}
    >
      {greaterThanPrecision(amount, displayPrecision) ? (
        <OnHoverToolTip
          hoverText={`${isNegative ? '-' : ''}${formatAmount(displayAmount, decimalPrecision)}`}
          width={300}
          placement={tipPlacement}
          displayText={`${isNegative ? '-' : ''}${truncateWithoutRounding({ amount: displayAmount, precision: displayPrecision, returnDisplayString: true })}... ${token}`}
        />
      ) : (
        `${isNegative ? '-' : ''}${displayAmount ? truncateWithoutRounding({ amount: displayAmount, precision: displayPrecision, returnDisplayString: true }) : 0} ${token}`
      )}
    </Box>
  );
};

export default TokenAmount;
