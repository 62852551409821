import { useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';

// Dynamically import lottie-web with no SSR
const lottie = dynamic(() => import('lottie-web'), { ssr: false });

export default function LottieWrapper({ animation, imageStyle }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      lottie.loadAnimation({
        container: containerRef.current,
        animationData: animation,
      });
    }
  }, [animation]);

  return <div ref={containerRef} className={imageStyle} />;
}
