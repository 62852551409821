import ReactMarkdown from 'react-markdown';
import { Box, styled } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));

const MarkdownWrapper = ({ children, ...props }) => (
  <StyledBox {...props}>
    <ReactMarkdown>{children}</ReactMarkdown>
  </StyledBox>
);

export default MarkdownWrapper;
