import { address } from './types.js';

export interface NetworkAddresses {
  universalRouterAddress: address;
  permit2Address: address;
  wethAddress: address;
}

export const NETWORK_ADDRESSES: { [chainId: number]: NetworkAddresses } = {
  // Ethereum Mainnet
  1: {
    universalRouterAddress: '0x66a9893cC07D91D95644AEDD05D03f95e1dBA8Af',
    permit2Address: '0x000000000022D473030F116dDEE9F6B43aC78BA3',
    wethAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  },
  // Sepolia Testnet
  11155111: {
    universalRouterAddress: '0x3a9d48ab9751398bbfa63ad67599bb04e4bdf98b',
    permit2Address: '0x000000000022D473030F116dDEE9F6B43aC78BA3',
    wethAddress: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
  },
};
