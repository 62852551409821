import {
  createContext,
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
// Hooks
import { useAuth } from '@/hooks/useAuth';
import { useNfts } from '@/hooks/useNfts';
import { useFeatureFlags } from '@/providers/FeatureFlagProvider';
import { useWeb3 } from '@/providers/Web3Provider';
// Utils
import { handleGetWallet, handleGetChainInfo } from '@/utils/wallet';
import {
  btcMigrationRequired,
  getUserAddresses,
} from '@/resources/wallet-service.resource';
import { brand } from '@/brand/brand';
import { config } from 'config';
// Helpers
import {
  walletReducer,
  initialWalletState,
  initWalletInfoState,
  initErrorState,
} from './helpers/walletHelpers';

export const WalletContext = createContext({});

export const useWalletContext = () => useContext(WalletContext);

const WalletProvider = ({ children }) => {
  const router = useRouter();
  const { featureFlags } = useFeatureFlags();
  const { fetcher, status, authStatuses } = useAuth();
  const { erc20Configurations, l2ChainInfo, provider } = useWeb3();

  const [state, dispatch] = useReducer(walletReducer, initialWalletState);
  const [walletError, setWalletError] = useState({
    core: initErrorState,
    legacy: initErrorState,
  });
  const [brandCoins, setBrandCoins] = useState(null);
  const [chainInfo, setChainInfo] = useState(null);
  const [btcMigRequired, setBtcMigRequired] = useState(false);
  const [walletInfo, setWalletInfo] = useState(initWalletInfoState);

  const serverError = walletError.core.server || walletError.legacy.server;
  const isWalletLoading =
    state.walletCoinState.loading || state.coreWalletCoinState.loading;

  const hasCoreWallet = state.coreWalletCoinState.hasWallet;
  const hasLegacyWallet = state.walletCoinState.hasWallet;

  const hasNoWallet =
    !hasLegacyWallet && !hasCoreWallet && !isWalletLoading && !serverError;

  const shouldUseCore = hasCoreWallet || hasNoWallet || !featureFlags.LegacyWallets;

  // Find out if we are using the legacy wallet or core wallet
  const getIsCore = router.query?.core ? router.query?.core === 'true' : shouldUseCore;

  const userWalletId = hasCoreWallet
    ? state.coreWalletCoins[0]?.walletId
    : state.walletCoins[0]?.walletId;

  const nft = useNfts({
    chainId: chainInfo?.chainId,
  });

  const handleSetError = (error) => {
    if (error === 'reset') {
      setWalletError({ core: initErrorState, legacy: initErrorState });
      return;
    }
    setWalletError((prev) => ({
      ...prev,
      [getIsCore ? 'core' : 'legacy']: {
        ...prev[getIsCore ? 'core' : 'legacy'],
        ...error,
      },
    }));
  };

  const findCoin = (symbol) => {
    const wallet = getIsCore ? state.coreWalletCoins : state.walletCoins;
    const coin = wallet?.find((coin) => coin?.coinSymbol === symbol);
    return coin || { coinSymbol: symbol };
  };

  const getUserWalletData = async () => {
    dispatch({
      type: 'SET_WALLET_LOADING',
      payload: true,
    });
    dispatch({
      type: 'SET_CORE_WALLET_LOADING',
      payload: true,
    });

    const coreAddresses = await fetcher(getUserAddresses(false)).catch((err) => {
      if (err?.response?.status === 404) return [];
    });
    let legacyAddresses = [];
    if (featureFlags.LegacyWallets) {
      legacyAddresses = await fetcher(getUserAddresses(true)).catch((err) => {
        if (err?.response?.status === 404) return [];
      });
    }

    const coreWalletId = Array.isArray(coreAddresses)
      ? coreAddresses.find((addr) => addr.network === 'ETH')?.address || ''
      : '';
    const coreBtc = Array.isArray(coreAddresses)
      ? coreAddresses.find((addr) => addr.network === 'BTC')?.address || ''
      : '';
    const legacyWalletId = Array.isArray(legacyAddresses)
      ? legacyAddresses.find((addr) => addr.network === 'ETH')?.address || ''
      : '';
    const legacyBtc = Array.isArray(legacyAddresses)
      ? legacyAddresses.find((addr) => addr.network === 'BTC')?.address || ''
      : '';
    const duplicateWallets = coreWalletId === legacyWalletId && !!coreWalletId; // Must have address because two empty strings set this true

    setWalletInfo({
      core: coreWalletId,
      legacy: legacyWalletId,
      coreBtc: coreBtc,
      legacyBtc: legacyBtc,
      hasTwoWallets: coreWalletId && legacyWalletId && coreWalletId !== legacyWalletId,
      hasNoWallet: !coreWalletId && !legacyWalletId,
      hasLegacyOnly: !coreWalletId && !!legacyWalletId,
      hasDuplicateWallets: duplicateWallets,
      loading: false,
    });

    const promises = [];
    // handle whether we should fetch legacy wallet balance data
    if (
      featureFlags.LegacyWallets &&
      !!legacyWalletId &&
      coreWalletId !== legacyWalletId
    ) {
      const legacyAddressPair = { eth: legacyWalletId, btc: legacyBtc };
      promises.push(getWalletItems(true, true, legacyAddressPair));
    } else {
      dispatch({
        type: 'SET_WALLET_STATE',
        payload: [],
      });
      dispatch({
        type: 'SET_HAS_DUPLICATE_WALLETS',
        payload: coreWalletId === legacyWalletId,
      });
      dispatch({
        type: 'SET_WALLET_LOADING',
        payload: false,
      });
    }
    if (coreWalletId) {
      const coreAddressPair = { eth: coreWalletId, btc: coreBtc };
      promises.push(getWalletItems(true, false, coreAddressPair, duplicateWallets));
      await Promise.all(promises);
    } else {
      dispatch({
        type: 'SET_CORE_WALLET_STATE',
        payload: [],
      });
      dispatch({
        type: 'SET_CORE_WALLET_LOADING',
        payload: false,
      });
    }
  };

  const getWalletItems = async (
    setLoading = false,
    isLegacy = false,
    addresses,
    duplicateWallets = false
  ) => {
    if (walletInfo?.hasNoWallet || erc20Configurations.length === 0) return;

    const coreAddresses = addresses || {
      eth: walletInfo?.core,
      btc: walletInfo?.coreBtc,
    };
    const legacyAddresses = addresses || {
      eth: walletInfo?.legacy,
      btc: walletInfo?.legacyBtc,
    };

    try {
      await handleGetWallet({
        addresses: isLegacy ? legacyAddresses : coreAddresses,
        dispatch,
        duplicateWallets: duplicateWallets || walletInfo?.hasDuplicateWallets,
        erc20Configurations,
        fetcher,
        isLegacy,
        l2ChainInfo,
        provider,
        setError: handleSetError,
        setLoading,
      });
    } finally {
      dispatch({
        type: isLegacy ? 'SET_WALLET_LOADING' : 'SET_CORE_WALLET_LOADING',
        payload: false,
      });
    }
  };

  const handleGetActiveWallets = async (setIsLoading = false) => {
    const calls = [];
    if (featureFlags.LegacyWallets) {
      if (walletInfo?.hasTwoWallets || walletInfo?.hasLegacyOnly) {
        calls.push(getWalletItems(setIsLoading, true));
      }
    }
    calls.push(getWalletItems(setIsLoading, false));
    await Promise.all(calls);
  };

  const handleClearWallet = () => {
    dispatch({ type: 'CLEAR_WALLET' });
    setBrandCoins(null);
    setWalletError({ core: initErrorState, legacy: initErrorState });
    setWalletInfo(initWalletInfoState);
    nft.dispatch({ type: 'RESET' });
    nft.resetGasFee();
  };

  const handleGetBtcMigRequired = async () => {
    await fetcher(btcMigrationRequired())
      .then((data) => {
        setBtcMigRequired(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const chainIdIntervalRef = useRef(null);
  useEffect(() => {
    const fetchAndSetChainId = async () => {
      if (status !== authStatuses.SIGNED_IN) {
        if (chainIdIntervalRef.current) clearInterval(chainIdIntervalRef.current);
        return;
      }

      const attemptFetch = async () => {
        const chainInfo = await handleGetChainInfo(fetcher);
        if (chainInfo) {
          setChainInfo(chainInfo);
          if (chainIdIntervalRef.current) clearInterval(chainIdIntervalRef.current);
          return true;
        }
        return false;
      };

      const success = await attemptFetch();
      if (!success) {
        chainIdIntervalRef.current = setInterval(attemptFetch, 20000);
      }
    };

    fetchAndSetChainId();

    return () => {
      if (chainIdIntervalRef.current) clearInterval(chainIdIntervalRef.current);
    };
  }, [status, authStatuses.SIGNED_IN]);

  // Init get legacy and core wallets
  useEffect(() => {
    // Clear wallet(s) on user logout
    if (status !== authStatuses.SIGNED_IN) {
      handleClearWallet();
      return;
    }
    getUserWalletData();

    handleGetBtcMigRequired();
  }, [status, erc20Configurations]);

  // Update wallets on interval
  useEffect(() => {
    if (isWalletLoading) return;
    nft.handleGetNftsByWallet({ ...walletInfo });

    const intervalRate = config.environment !== 'production' ? 180000 : 60000;

    const updateWalletInterval = setInterval(handleGetActiveWallets, intervalRate);

    return () => clearInterval(updateWalletInterval);
  }, [isWalletLoading, walletInfo?.hasNoWallet]);

  // Set brand coin
  useEffect(() => {
    setBrandCoins({
      isCore: getIsCore,
      l1: findCoin(brand.coin),
      l2: findCoin(`${brand.coin}-P`),
    });
  }, [state.walletCoins, state.coreWalletCoins, getIsCore]);

  return (
    <WalletContext.Provider
      value={{
        brandCoins,
        btcMigRequired,
        chainInfo,
        findCoin,
        getIsCore,
        getWalletItems,
        handleClearWallet,
        handleGetActiveWallets,
        hasCoreWallet,
        hasNoWallet,
        isWalletLoading,
        nft,
        serverError,
        userWalletId,
        walletInfo,
        coreGetWalletItems: getWalletItems,
        coreWalletCoins: state.coreWalletCoins,
        coreWalletCoinState: state.coreWalletCoinState,
        coreWalletError: walletError.core,
        hasDuplicateWallets: state.hasDuplicateWallets,
        isCoinLoading: state.isCoinLoading,
        walletCoins: state.walletCoins,
        walletCoinState: state.walletCoinState,
        walletError: walletError.legacy,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
