import { brand } from '@/brand/brand';

export const initErrorState = {
  coin: false,
  server: false,
};

export const initialWalletState = {
  walletCoins: [],
  coreWalletCoins: [],
  walletCoinState: {
    isImported: false,
    hasWallet: false,
    loading: true,
    error: false,
  },
  coreWalletCoinState: {
    hasWallet: false,
    loading: true,
    error: false,
  },
  ethWallet: {},
  coreEthWallet: {},
  ethWalletState: {
    loading: true,
  },
  coreEthWalletState: {
    loading: true,
  },
  hasDuplicateWallets: false,
  isCoinLoading: false,
};

export const initWalletInfoState = {
  core: '',
  legacy: '',
  loading: true,
  hasTwoWallets: false,
  hasNoWallet: false,
  hasLegacyOnly: false,
  hasMigrated: false,
};

export const ethWalletObj = (eth) => ({
  id: eth.walletId,
  name: brand.brandName,
  icon: eth.coinLogo,
  totalUSD: eth.usd,
  totalETH: eth.coinAmt,
  valid: eth.valid,
  priceUsd: eth.priceUsd,
});

export const walletReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CORE_WALLET_LOADING':
      return {
        ...state,
        coreWalletCoinState: { ...state.coreWalletCoinState, loading: action.payload },
      };
    case 'SET_WALLET_LOADING':
      return {
        ...state,
        walletCoinState: { ...state.walletCoinState, loading: action.payload },
      };
    case 'SET_CORE_WALLET_STATE': {
      const eth = action.payload.find((coin) => coin?.coinSymbol === 'ETH');
      if (!eth?.walletId) return state;

      return {
        ...state,
        coreWalletCoinState: {
          ...state.coreWalletCoinState,
          loading: false,
          hasWallet: true,
        },
        coreWalletCoins: action.payload,
        coreEthWallet: ethWalletObj(eth),
        coreEthWalletState: { loading: false },
      };
    }
    case 'SET_WALLET_STATE': {
      const eth = action.payload.find((coin) => coin?.coinSymbol === 'ETH');
      if (!eth?.walletId) return state;

      return {
        ...state,
        walletCoinState: { ...state.walletCoinState, loading: false, hasWallet: true },
        walletCoins: action.payload,
        ethWallet: ethWalletObj(eth),
        ethWalletState: { loading: false },
      };
    }
    case 'SET_HAS_CORE_WALLET_ERROR':
      return {
        ...state,
        coreWalletCoinState: {
          ...state.coreWalletCoinState,
          loading: false,
          error: action.payload,
        },
      };
    case 'SET_HAS_WALLET_ERROR':
      return {
        ...state,
        walletCoinState: {
          ...state.walletCoinState,
          loading: false,
          error: action.payload,
        },
      };
    case 'SET_CORE_ETH_WALLET':
      return {
        ...state,
        coreEthWallet: action.payload,
      };
    case 'SET_ETH_WALLET':
      return {
        ...state,
        ethWallet: action.payload,
      };
    case 'SET_CORE_ETH_WALLET_STATE':
      return {
        ...state,
        coreEthWalletState: { loading: action.payload },
      };
    case 'SET_ETH_WALLET_STATE':
      return {
        ...state,
        ethWalletState: { loading: action.payload },
      };
    case 'CLEAR_WALLET':
      return initialWalletState;
    case 'SET_HAS_DUPLICATE_WALLETS':
      return {
        ...state,
        hasDuplicateWallets: action.payload,
      };

    case 'SET_WALLET_COIN_STATE':
      return {
        ...state,
        walletCoinState: {
          ...state.walletCoinState,
          ...action.payload,
        },
      };
    case 'SET_COIN_LOADING':
      return {
        ...state,
        isCoinLoading: action.payload,
      };
    case 'UPDATE_SINGLE_COIN': {
      const { coin, isLegacy } = action.payload;
      if (!coin) return state;

      if (isLegacy) {
        const updatedWalletCoins = state.walletCoins.map((existingCoin) =>
          existingCoin.coinSymbol === coin.coinSymbol ? coin : existingCoin
        );

        // If coin doesn't exist in current wallet, add it
        if (!state.walletCoins.find((c) => c.coinSymbol === coin.coinSymbol)) {
          updatedWalletCoins.push(coin);
        }

        return {
          ...state,
          walletCoins: updatedWalletCoins,
          walletCoinState: {
            ...state.walletCoinState,
            hasWallet: true,
            loading: false,
          },
        };
      }

      const updatedCoreWalletCoins = state.coreWalletCoins.map((existingCoin) =>
        existingCoin.coinSymbol === coin.coinSymbol ? coin : existingCoin
      );

      // If coin doesn't exist in current wallet, add it
      if (!state.coreWalletCoins.find((c) => c.coinSymbol === coin.coinSymbol)) {
        updatedCoreWalletCoins.push(coin);
      }

      return {
        ...state,
        coreWalletCoins: updatedCoreWalletCoins,
        coreWalletCoinState: {
          ...state.coreWalletCoinState,
          hasWallet: true,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
