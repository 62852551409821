import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { ethers } from 'ethers';
import { handleGetChainInfo } from '@/utils/wallet';
import { useAuth } from '@/hooks/useAuth';
import { getAllERC20, getL2ChainInfo } from '@/resources/wallet-service.resource';
import { useNfts } from '@/hooks/useNfts';
import { UniswapSDK } from './swap/sdk';
import { ERC20_ABI } from './swap/abi';

import {
  getEncryptedData,
  decryptMnemonic,
  initializeTokenConfig,
  updateTokenConfig,
} from '@/providers/helpers/web3Helper';
import {
  getL2Fees,
  sendBTCTransaction,
  sendERCTransaction,
  sendETHTransaction,
  sendL2Transaction,
} from '@/providers/helpers/transactionHelpers';

const Web3Context = createContext(null);

// get from backend?
const NETWORKS = {
  1: {
    name: 'Mainnet',
  },
  11155111: {
    name: 'Sepolia',
  },
};

export const useWeb3 = () => {
  const context = useContext(Web3Context);
  if (!context) {
    throw new Error('useWeb3 must be used within a Web3Provider');
  }
  return context;
};

export function Web3Provider({ children }) {
  const { fetcher, status, authStatuses } = useAuth();
  const { blockChainConfig } = useNfts({ chainId: 1 });
  const chainIdIntervalRef = useRef(null);
  const sdkRef = useRef(null);

  const [error, setError] = useState(null);
  const [chainInfo, setChainInfo] = useState(null);
  const [l2ChainInfo, setL2ChainInfo] = useState(null);
  const [tokenConfig, setTokenConfig] = useState(null);
  const [networks, setNetworks] = useState(NETWORKS);
  const [erc20Configurations, setErc20Configurations] = useState([]);

  const getTokenAddress = useCallback(
    (tokenSymbol) => {
      if (!erc20Configurations || erc20Configurations.length === 0) {
        console.error('Token configurations state:', {
          isNull: !erc20Configurations,
          length: erc20Configurations?.length || 0,
        });
        throw new Error('Token configurations not loaded yet');
      }

      if (tokenSymbol.toUpperCase() === 'ETH') {
        return UniswapSDK.NATIVE_ETH_ADDRESS;
      }

      const tokenConfig = erc20Configurations.find(
        (token) => token.symbol.toUpperCase() === tokenSymbol.toUpperCase()
      );

      if (!tokenConfig) {
        console.error(
          'Available tokens:',
          erc20Configurations.map((t) => ({
            symbol: t.symbol,
            address: t.contractAddress,
          }))
        );
        throw new Error(
          `Token not found: ${tokenSymbol}. Available tokens: ${erc20Configurations.map((t) => t.symbol).join(', ')}`
        );
      }

      if (!tokenConfig.contractAddress) {
        throw new Error(`Contract address not found for token: ${tokenSymbol}`);
      }

      return tokenConfig.contractAddress;
    },
    [erc20Configurations]
  );

  useEffect(() => {
    if (authStatuses.SIGNED_IN !== status) {
      return;
    }
    const initChainId = 1;
    const fetchAndSetChainId = async () => {
      if (status !== authStatuses.SIGNED_IN) {
        if (chainIdIntervalRef.current) clearInterval(chainIdIntervalRef.current);
        return;
      }

      getL2ChainData();

      const chainInfo = await handleGetChainInfo(fetcher);
      if (chainInfo) {
        setChainInfo(chainInfo);

        // After getting chainInfo, fetch ERC20 configs
        try {
          const { erc20Configurations } = await fetcher(getAllERC20());
          setErc20Configurations(erc20Configurations);
          const updatedConfig = updateTokenConfig(
            initializeTokenConfig(chainInfo.chainId),
            erc20Configurations
          );
          setTokenConfig(updatedConfig);
        } catch (err) {
          console.error('Failed to fetch ERC20 tokens:', err);
        }
      }
    };

    if (chainInfo?.chainId) {
      // Set initial placeholder values
      setTokenConfig(initializeTokenConfig(initChainId));

      // Immediately fetch ERC20 configs to update the placeholders
      fetcher(getAllERC20())
        .then((data) => {
          const updatedConfig = updateTokenConfig(
            initializeTokenConfig(initChainId), // Use fresh init config
            data.erc20Configurations
          );
          setTokenConfig(updatedConfig);
          setErc20Configurations(data.erc20Configurations);
        })
        .catch((err) => {
          console.error('Failed to fetch ERC20 tokens:', err);
        });

      return;
    } else {
      fetchAndSetChainId();
    }
  }, [status, authStatuses.SIGNED_IN, fetcher]);

  useEffect(() => {
    if (blockChainConfig.length) {
      // If not sepolia or mainnet, update network data from db
      blockChainConfig.forEach((blockchain) => {
        if (blockchain.chainId !== 1 && blockchain.chainId !== 11155111) {
          setNetworks((prevNetworks) => ({
            ...prevNetworks,
            [blockchain.chainId]: {
              name: blockchain.name,
              rpcUrl: blockchain.rpcUrl,
            },
          }));
        }
      });
    }
  }, [blockChainConfig]);

  const provider = useMemo(() => {
    if (!chainInfo) return null;
    try {
      const chainId = chainInfo?.chainId;
      const network = networks[chainId];

      if (!network?.name) {
        throw new Error(`Unsupported chain ID: ${chainId}`);
      }
      // using nextjs server side api call to protect key
      const url = `${window.location.origin}/api/infura`;
      const provider = new ethers.providers.JsonRpcProvider(url, Number(chainId));

      // Initialize UniswapSDK
      if (!sdkRef.current) {
        sdkRef.current = new UniswapSDK({
          jsonRpcUrl: url,
          chainId: Number(chainId),
          debug: process.env.NODE_ENV === 'development',
        });
        sdkRef.current.init().catch(console.error);
      }

      return provider;
    } catch (err) {
      setError('Failed to initialize provider');
      console.error('Provider initialization error:', err);
      return null;
    }
  }, [chainInfo?.chainId, networks]);

  const getL2ChainData = async () =>
    await fetcher(getL2ChainInfo())
      .then((data) => {
        setL2ChainInfo(data);
      })
      .catch(() => console.error('Error fetching L2 chain data'));

  async function getBalance(address) {
    try {
      if (!provider) throw new Error('Provider not initialized');
      const balance = await provider.getBalance(address);
      return ethers.formatEther(balance);
    } catch (err) {
      setError('Failed to get balance');
      console.error('Balance fetch error:', err);
      throw err;
    }
  }

  async function getTokenBalance(tokenAddress, walletAddress) {
    try {
      if (!provider) throw new Error('Provider not initialized');
      const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
      const balance = await tokenContract.balanceOf(walletAddress);
      const decimals = await tokenContract.decimals();

      return ethers.utils.formatUnits(balance, decimals);
    } catch (err) {
      setError('Failed to get token balance');
      console.error('Token balance fetch error:', err);
      throw err;
    }
  }

  async function getLatestBlock() {
    try {
      if (!provider) throw new Error('Provider not initialized');
      return await provider.getBlock('latest');
    } catch (err) {
      setError('Failed to get latest block');
      console.error('Block fetch error:', err);
      throw err;
    }
  }

  async function signTransaction(passcode, to, value) {
    try {
      const encryptedData = await getEncryptedData(fetcher);

      if (!encryptedData) throw new Error('No encrypted data available');

      let mnemonic = null;
      let wallet = null;
      let signedTx = null;

      try {
        mnemonic = decryptMnemonic(encryptedData, passcode);
        wallet = ethers.Wallet.fromMnemonic(mnemonic);
        const connectedWallet = wallet.connect(provider);
        const latestNonce = await provider.getTransactionCount(wallet.address, 'latest');

        const tx = {
          to,
          value: ethers.utils.parseEther(value.toString()),
          nonce: latestNonce,
          gasLimit: ethers.utils.hexlify(100000),
          gasPrice: await provider.getGasPrice(),
        };

        signedTx = await connectedWallet.signTransaction(tx);

        return signedTx;
        // if you are ready to send transaction on signing instead of return signedTx we can use const txResponse = await provider.sendTransaction(signedTx);
      } finally {
        // Clear sensitive data from memory
        if (mnemonic) mnemonic = null;
        wallet = null;
      }
    } catch (err) {
      console.error('Transaction signing error:', err);
      throw err;
    }
  }

  async function sendWeb3Transaction({
    passcode,
    from,
    to,
    amount,
    l2Send = false,
    symbol,
    decimals,
  }) {
    const network = await provider.getNetwork();
    if (!network) return;
    const encryptedData = await getEncryptedData(fetcher);
    let mnemonic = '';

    mnemonic = decryptMnemonic(encryptedData, passcode);
    let response = null;
    if (symbol === 'ETH') {
      response = await sendETHTransaction({
        to,
        value: amount,
        provider,
        mnemonic,
        chainId: chainInfo?.chainId,
      });
      mnemonic = '';
    } else if (symbol === 'BTC') {
      response = await sendBTCTransaction({
        to: to,
        value: amount,
        mnemonic,
        network,
        from: from,
      });
      mnemonic = '';
    } else if (l2Send) {
      response = await sendL2Transaction({
        to,
        value: amount,
        mnemonic,
        l2ChainInfo,
      });
      mnemonic = '';
    } else {
      response = await sendERCTransaction({
        to,
        value: amount,
        provider,
        mnemonic,
        chainId: chainInfo?.chainId,
        tokenAddress: tokenConfig[chainInfo?.chainId]?.tokens[symbol]?.address,
        decimals,
      });
      mnemonic = '';
    }
    return response;
  }

  const getL2GasFees = async ({ amount, to, walletAddress }) => {
    try {
      const fees = await getL2Fees({ amount, to, walletAddress, l2ChainInfo });
      return fees;
    } catch (err) {
      console.error('Failed to get L2 gas fees:', err);
      throw err;
    }
  };

  const getSwapQuote = useCallback(
    async (fromToken, toToken, amount) => {
      try {
        if (!provider) throw new Error('Provider not initialized');
        if (!tokenConfig) throw new Error('Token config not initialized');
        if (!sdkRef.current) throw new Error('SDK not initialized');
        if (!erc20Configurations || erc20Configurations.length === 0) {
          console.error('Token configurations missing:', {
            isNull: !erc20Configurations,
            length: erc20Configurations?.length || 0,
            chainId: chainInfo?.chainId,
          });
          throw new Error('Token configurations not loaded yet');
        }

        // Get token addresses first, regardless of network
        const fromTokenAddress = getTokenAddress(fromToken);
        const toTokenAddress = getTokenAddress(toToken);

        const quote = await sdkRef.current.getQuote({
          fromTokenAddress,
          toTokenAddress,
          amountIn: amount.toString(),
          slippagePercentage: 0.5, // Default 0.5% slippage
        });

        if (!quote) throw new Error('Failed to get quote');

        // Match the exact field names from the quote response
        const result = {
          protocol: quote.protocol,
          estimatedAmountOut: quote.estimatedAmountOut,
          amountOutMinimum: quote.amountOutMinimum,
          estimatedGasAmount: quote.estimatedGasAmount,
          route: quote.route || [],
          fromTokenAddress,
          toTokenAddress,
        };

        console.error('Returning quote:', result);
        return result;
      } catch (err) {
        console.error('Failed to get swap quote:', err);
        throw err;
      }
    },
    [provider, tokenConfig, chainInfo, getTokenAddress, erc20Configurations]
  );

  async function executeSwap({ fromToken, toToken, amount, slippage = 0.5, wallet }) {
    if (!provider) throw new Error('Provider not initialized');
    if (!tokenConfig) throw new Error('Token config not initialized');
    if (!wallet) throw new Error('Wallet not provided');
    if (!sdkRef.current) throw new Error('Universal Router SDK not initialized');

    try {
      const fromTokenAddress = getTokenAddress(fromToken);
      const toTokenAddress = getTokenAddress(toToken);

      const swapResult = await sdkRef.current.swap({
        mnemonic: wallet.mnemonic.phrase,
        fromTokenAddress,
        toTokenAddress,
        amountIn: amount.toString(),
        slippagePercentage: slippage,
        chainId: chainInfo.chainId,
      });

      // If we got a successful result with hash and amountOut
      if (swapResult && swapResult.hash) {
        return {
          success: true,
          hash: swapResult.hash,
          receipt: null, // Receipt will be available after confirmation
          amountIn: amount.toString(),
          amountOut: swapResult.amountOut,
          estimatedGas: '0',
          status: 'pending',
        };
      }

      throw new Error('Swap failed: Invalid response from SDK');
    } catch (err) {
      throw new Error(`Swap failed: ${err.reason || err.message}`);
    }
  }

  async function getWalletFromPasscode(passcode) {
    const encryptedData = await getEncryptedData(fetcher);
    if (!encryptedData) throw new Error('No encrypted data available');

    try {
      const mnemonic = decryptMnemonic(encryptedData, passcode);

      const wallet = ethers.Wallet.fromMnemonic(mnemonic);

      return wallet;
    } catch (err) {
      throw new Error('Invalid passcode: ', +err.message);
    }
  }

  const value = {
    chainInfo,
    erc20Configurations,
    error,
    executeSwap,
    getBalance,
    getL2GasFees,
    getSwapQuote,
    getTokenBalance,
    getLatestBlock,
    getWalletFromPasscode,
    l2ChainInfo,
    provider,
    sendWeb3Transaction,
    signTransaction,
    tokenConfig,
  };

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
}
